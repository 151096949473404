import React, { useState } from "react";
import "./apply.css";
import { useLocation } from "react-router-dom";
import { AiFillFilePdf, AiFillHeart } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import Top from "../top section/Top";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import axios from "axios";
import Cookies from "universal-cookie";
import { BASE_URL } from "../../../context/config";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
const Apply = (props) => {
  const cookies = new Cookies();
  const session = useState(cookies.get("sessionid"));
  const location = useLocation();
  const role = session[0].role;
  const { data } = location.state;
  const [offers, setData] = useState(data);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  console.log(offers);

  function getTags(allTags) {
    const items = allTags.split(",");
    return items.map((item, index) => (
      <div key={index} className="tags">
        <p
          style={{
            backgroundColor: "#f29f9f",
            padding: "5px",
            borderRadius: 5,
            fontSize: 12,
          }}
        >
          {" "}
          {item}
        </p>
      </div>
    ));
  }
  function updateSate(event, index) {
    const jobData = {
      primaryemail: session[0].primaryemail,
      sessionid: session[0].sessionid,
      jobid: index,
    };
    const headers = {
      "Content-Type": "application/json",
    };

    const newState = offers.map((obj) => {
      console.log(index);

      if (obj.id === index) {
        if (obj.likeColor === "#f29f9f") {
          axios
            .post(`${BASE_URL}/saveforlater/opportunity/deletejob`, jobData, {
              headers,
            })
            .then((response) => {
              console.log(response.data);
            })
            .catch((error) => {
              console.log(error);
              setError("Could not all offers");
            })
            .finally(() => {
              setIsLoading(false);
            });
          return { ...obj, likeColor: "#000000" };
        } else {
          axios
            .post(`${BASE_URL}/saveforlater/opportunity/savejob`, jobData, {
              headers,
            })
            .then((response) => {
              console.log(response.data);
            })
            .catch((error) => {
              console.log(error);
              setError("Could not all offers");
            })
            .finally(() => {
              setIsLoading(false);
            });
          return { ...obj, likeColor: "#f29f9f" };
        }
      }

      return obj;
    });

    setData(newState);
  }

  const handleClick = (event, app_link) => {
    const trackData = {
      primaryemail: session[0].primaryemail,
      sessionid: session[0].sessionid,
      jobid: offers.id,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(`${BASE_URL}/track/opportunity/application`, trackData, { headers })
      .then((response) => {
        console.log("tracked");
      })
      .catch((error) => {
        console.log("Could not add track");
      })
      .finally(() => {
        console.log("done");
        if (app_link.includes("@")) {
          window.location.href = "mailto:" + app_link;
          event.preventDefault();
        } else {
          window.open(app_link);
          event.preventDefault();
        }
      });
  };
  const Link = (props) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    return (
      <a rel="nofollow noreferrer" href={url} target="_blank">
        {props.children}
      </a>
    );
  };

  function findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === "LINK"
      );
    }, callback);
  }

  const handleDownload = async () => {
    console.log(offers.fileid);

    try {
      const response = await axios.get(
        `${BASE_URL}/media/download/${offers.fileid}`,
        {
          responseType: "blob", // Set the response type to 'blob'
        }
      );
      const contentDisposition = response.headers["content-disposition"];

      const filename = contentDisposition.split("filename=")[1];

      // Extract the file extension
      // const fileExtension = filename.split('.').pop();
      // console.log(fileExtension)
      // Extract the file extension
      console.log(filename);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", filename); // Set the desired filename
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up the object URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  function buildOffers(offers) {
    return (
      <div className="secContainer flex">
        <div className="singleItem">
          {/* <div className="details grid"> */}
          <div className="details">
            <div className="heading flex">
              <div className="title">
                <h5
                  style={{
                    marginBottom: 10,
                    fontWeight: "normal",
                    color: "#b54b4b",
                    fontWeight: 600,
                    // textDecoration: "underline",
                  }}
                >
                  {offers.title}
                </h5>
              </div>

              <div className="tools flex">
                {role.includes("admin") ? (
                  <div></div>
                ) : (
                  <button className="fav-btutton btn">
                    <AiFillHeart
                      className="icon"
                      style={{ color: offers.likeColor }}
                      onClick={(event) => updateSate(event, offers.id)}
                    />
                  </button>
                )}
              </div>
            </div>
            <div className="title">
              <div className="comTitle">
                <h6>Company: {offers.company}</h6>
              </div>
            </div>
            <div className="">
              <p className="rule-title">Job Description</p>
              <hr className="horizontal-rule" />
            </div>
            <div className="breifDescription flex">
              <p>{offers.short_description}</p>
            </div>
            <div className="properties flex">
              <p>
                <GoLocation size={16} />
              </p>
              <p className="custom-address_title">{offers.location}</p>
              <p className="custom-address_title">
                ${offers.stipend} / Monthly
              </p>

              <p className="custom-address_title">
                {" "}
                | Deadline: {offers["end date"]}
              </p>
            </div>
            <div className="allTags custom-address_title flex">
              {getTags(offers.tags)}
            </div>
            <div>
              <p
                style={{
                  backgroundColor: "#9FF2AD",
                  width: "fit-content",
                  padding: "5px",
                  borderRadius: 5,
                  fontSize: 12,
                }}
              >
                {offers.type}
              </p>
            </div>

            {offers.detailedInfo == " " ? (
              <div />
            ) : (
              <div
                style={{
                  marginTop: 20,
                }}
              >
                <div className="heading">
                  <h3>Detailed Information</h3>
                </div>
                <div className="breifDescription flex" />
                <p>{offers.detailedInfo}</p>
              </div>
            )}

            {offers.fileid && (
              <div>
                <p>Click the link below to download the attachment:</p>
                <div>
                  <br />
                  <AiFillFilePdf
                    onClick={handleDownload}
                    size={100}
                    className="btn"
                  />
                </div>
              </div>
            )}
            <div className="heading">
              {offers.contactname == "" ? (
                <div />
              ) : (
                <p>Contact Person: {offers.contactname}</p>
              )}
              {offers.contactemail == "" ? (
                <div />
              ) : (
                <p>Contact Email: {offers.contactemail}</p>
              )}
            </div>
            {role.includes("admin") ? (
              <div></div>
            ) : (
              <button
                className="btn"
                onClick={(event) => handleClick(event, offers.application_link)}
              >
                Apply
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mainContent">
      <Top />
      <div className="bottom flex">
        <div className="listingSection">
          <div className="heading flex">
            <h1>Apply To Offer</h1>
          </div>
          {buildOffers(offers)}
        </div>
      </div>
    </div>
  );
};

export default Apply;
