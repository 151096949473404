import Chart from "chart.js/auto";
import React from "react";
import { Bar } from "react-chartjs-2";

const TagChart = () => {
  return (
    <Bar
      data={{
        labels: Array.from(
          { length: 20 },
          () => Math.floor(Math.random() * 100) + 1
        ),
        datasets: [
          {
            label: "Company tag",
            backgroundColor: "dodgerblue",
            data: Array.from(
              { length: 20 },
              () => Math.floor(Math.random() * 100) + 1
            ),
            barThickness: 20,
          },
        ],
      }}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: false,
            text: "",
          },
          legend: {
            display: true,
            position: "top",
          },
        },
        scales: {
          x: {
            // display: true,
            position: "bottom",
            ticks: {
              color: "#afafcb",
            },
            grid: {
              drawBorder: false,
            },
          },
          y: {
            display: true,
            grid: {
              //   display: true,
              color: "#DEDEDE",
              borderDash: [10, 3],
            },
          },
        },
      }}
    />
  );
};

export default TagChart;
