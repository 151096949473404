import React from 'react'
import { Link } from "react-router-dom";
import "./style.css";
import { IoCloseOutline } from "react-icons/io5";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import {
  sideBarAdmin,
  sideBarManager,
  sideBarDataUser,
} from "../../sidebardata";
const MobileNav = ({ showNav, setshowNav }) => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [data, setdata] = useState([]);
  const session = useState(cookies.get("sessionid"));
  useEffect(() => {
    try {
      if (session[0].role === "systemadmin") {
        return setdata(sideBarAdmin);
      } else if (session[0].role == "admin") {
        return setdata(sideBarManager);
      } else if (session[0].role === "user") {
        return setdata(sideBarDataUser);
      } else {
        return null; // Return default or empty content if none of the conditions match
      }
    } catch (error) {
      navigate("/login");
    }
  }, []);
  const mobileNavContent = useMemo(() => {
    try {
      if (session[0].role === "systemadmin") {
        return sideBarAdmin;
      } else if (session[0].role == "admin") {
        return sideBarManager;
      } else if (session[0].role === "user") {
        return sideBarDataUser;
      } else {
        return []; // Return default or empty content if none of the conditions match
      }
    } catch (error) {
      navigate("/login");
    }
  }, [session[0]]);

  return (
    <div className="mobile-nav">
      <div className="mobile-top__menu">
        <div className="mobile-nav_title">Opportunities Board</div>
        <div
          className="h-[30px] w-[30px]"
          onClick={() => setshowNav((prev) => !prev)}
        >
          <IoCloseOutline size={23} />
          {/* <img className="" src="@/assets/svg/cancel.svg" /> */}
        </div>
      </div>
      <div className="mobile-items_cover">
        {data.map((i) => (
          <div
            key={i.title}
            onClick={() => {
              console.log(i.title);
              setshowNav((prev) => false);
              navigate(i.link);
            }}
            // to={i.link}
            className="mobile-items-link"
          >
            {i.title}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileNav;
