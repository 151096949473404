import "./button.css";

const Button = ({ loading, event, text }) => {
  return (
    <div className="btn-cover">
      <button disabled={loading} onClick={event} className="btn-text">
        {loading ? "loading..." : text}
      </button>
    </div>
  );
};

export default Button;
