import React, { useEffect, useRef } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import CircularProgress from "@mui/material/CircularProgress";
import { BsThreeDotsVertical } from "react-icons/bs";
import Backdrop from "@mui/material/Backdrop";
import Papa from "papaparse";
import "./users.css";
import { BASE_URL } from "../../../context/config";
import axios from "axios";
import Top from "../top section/Top";
import { BiSearchAlt } from "react-icons/bi";
import { useState } from "react";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import UserModal from "../../modal/UserModal";
import { toast } from "react-hot-toast";

const Users = () => {
  const cookies = new Cookies();
  const session = useState(cookies.get("sessionid"));
  const navigate = useNavigate();
  const theme = useTheme();
  const [users, setUsers] = useState([]);
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [error, setError] = useState(null);
  const [allUsers, setAllUsers] = useState(null);
  const [fileContent, setFileContent] = useState("");
  const [CSVData, setCSVData] = useState();
  const [input, setinput] = useState("");
  const [allroles, setallroles] = useState([]);
  const [userdata, setuserdata] = useState({});
  var commonConfig = { delimiter: "," };
  const inputRef = useRef(null);
  const handleClick = () => {
    inputRef.current.click();
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    // Check if a file is selected
    if (!file) {
      return;
    }
    Papa.parse(file, {
      ...commonConfig,
      header: true,
      complete: (result) => {
        uploadData(result.data);
      },
    });
  };

  const uploadData = (data) => {
    setIsLoading(true);
    let csvString = "";
    for (var i = 0; i < data.length; i++) {
      const row = Object.values(data[i]).join(",");
      if (row != "") {
        csvString = csvString + row + "\n";
      }
    }

    const uploadPayload = {
      creatoremail: session[0].primaryemail,
      sessionid: session[0].sessionid,
      csv: csvString,
    };

    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(`${BASE_URL}/onboarding/users/uploaduserrecords`, uploadPayload, {
        headers,
      })
      .then((response) => {
        setError("USers uploaded");
        window.location.reload();
      })
      .catch((error) => {
        setError("Could not load User");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleSelectionChange = (selection) => {
    setSelectedRows(selection);
  };
  const handleSearch = (value) => {
    try {
      // setIsLoading(true);
      // console.log(value, "---");
      // If the search input is empty, set the users state to allUsers
      if (value.trim() === "") {
        setUsers(allUsers);
        // setIsLoading(false);
      } else {
        // Filter the users based on the search input
        const filteredUsers = users.filter(
          (User) =>
            User?.name.toLowerCase().includes(value.toLowerCase()) ||
            User?.primaryemail.toLowerCase().includes(value.toLowerCase())
          // You can add more properties to search in if needed
        );
        console.log(filteredUsers, "filterd list");
        // setUsers(filteredUsers);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  const handleDeleteUser = (e) => {
    if (selectedRows.length == 0) {
      setError("You must select at least one element");
    } else {
      setIsLoading(true);
      const toDelete = selectedRows;
      
      for (let i = 0; i < toDelete.length; i++) {
        const deleteData = {
          primaryemail: session[0].primaryemail,
          sessionid: session[0].sessionid,
          useremail: toDelete[i],
        };
        const headers = {
          "Content-Type": "application/json",
        };
        // Fetch the data from the API using Axios
        let path = "deleteuseraccount";
        const oneUSer = users.filter((item) => item.id == toDelete[i])[0].role;
        if (oneUSer.includes("admin")) {
          path = "deleteadminaccount";
        }
        axios
          .post(`${BASE_URL}/account/admin/${path}`, deleteData, { headers })
          .then((response) => {
            setUsers(users.filter((item) => !selectedRows.includes(item.id)));
          })
          .catch((error) => {
            // Handle error if the request fails
            console.log(error);
            setError("Error deleting User");
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };
  const handleAssignRole = (e) => {
    navigate("../add_user", {
      state: {
        data: users.filter((item) => selectedRows.includes(item.id))[0],
      },
    });
  };
  const handleSendInvites = (e) => {
    setIsLoading(true);
    if (selectedRows.length == 0) {
      setError("You must select at least one element");
      setIsLoading(false);
    } else {
      console.log(users);
      const filteredArray = users.filter((obj) =>
        selectedRows.includes(obj.email)
      );
      let toSend = [];
      for (let i = 0; i < filteredArray.length; i++) {
        toSend.push({
          primaryemail: filteredArray[i].email,
          secondaryemail: filteredArray[i].email,
        });
      }
      console.log(toSend);
      const invitePayload = {
        initiatorprimaryemail: session[0].primaryemail,
        sessionid: session[0].sessionid,
        accountinfo: JSON.stringify(toSend),
      };
      console.log(invitePayload);
      const headers = {
        "Content-Type": "application/json",
      };
      
      axios
        .post(
          `${BASE_URL}onboarding/accounts/sendmultipleinvites`,
          invitePayload,
          {
            headers,
          }
        )
        .then((response) => {
          setError("Invitations Sent");
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
          setError("Could not send invites");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  const columns = [
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },

    {
      field: "sentstatus",
      headerName: "Sent Status",
      flex: 1,
      cellClassName: "name-column--cell",
    },

    {
      field: "acceptancestatus",
      headerName: "Acceptance Status",
      flex: 1,
    },

    {
      field: "role",
      headerName: "User Role",
      flex: 1,
    },
    {
      field: "activestatus",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,

      renderCell: (params) => <CustomComponent value={params} />,
    },
  ];

  const CustomComponent = ({ value }) => (
    <div
      className="action-btn"
      onClick={() => {
        setuserdata(value);
        setmodal(true);
      }}
    >
      <BsThreeDotsVertical />
    </div>
  );

  useEffect(() => {
    // Define the API endpoint to get the list of countries

    const userData = {
      callerid: session[0].primaryemail,
      sessionid: session[0].sessionid,
    };
    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post("/roles/seeallroles", userData, headers)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => toast.error(err.message));
  }, []);

  useEffect(() => {
    // Define the API endpoint to get the list of countries

    const userData = {
      primaryemail: session[0].primaryemail,
      sessionid: session[0].sessionid,
      startdate: "",
      enddate: "",
      limit: 100,
      offset: 0,
    };
    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(`${BASE_URL}/account/admin/getallusers`, userData, { headers })
      .then((response) => {
        // Handle successful response and update the 'countries' state
        
        var tempData = response.data.body;
        tempData.forEach((obj) => {
          obj.id = obj.email;
          const dateString = obj.dateregistered
            ? obj.dateregistered
            : obj.datecreated;

          const date = new Date(dateString);
          obj.sentstatus = obj.sentstatus ? "Invited" : "Invitation not sent";
          obj.acceptancestatus = obj.acceptancestatus ? "Accepted" : "Pending";
          const day = date.getDate().toString().padStart(2, "0");
          const month = (date.getMonth() + 1).toString().padStart(2, "0");
          const year = date.getFullYear();
          const formattedDate = `${day}-${month}-${year}`;
          obj.dateadded = formattedDate;

          delete obj.Userid;
        });
        setUsers(tempData);
        setAllUsers(tempData);
      })
      .catch((error) => {
        // Handle error if the request fails
        console.error("Error fetching countries:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [null]);
  const handlePopupClose = () => {
    setError(null);
  };
  const [modal, setmodal] = useState(false);
  return (
    <>
      {modal && (
        <UserModal modal={modal} setmodal={setmodal} data={userdata?.row} />
      )}
      <div className="mainContent">
        <Top />
        <div className="heading flex">
          <h1>Users</h1>
          <div className="tools flex">
            <div>
              <input
                style={{ display: "none" }}
                ref={inputRef}
                type="file"
                onChange={handleFileChange}
              />

              <button className="btn" onClick={handleClick}>
                Upload Users.
              </button>
            </div>

            <button className="btn" onClick={handleSendInvites}>
              Send Invites
            </button>
            <button className="btn" onClick={handleAssignRole}>
              Create Single
            </button>
            <button className="btn" onClick={handleDeleteUser}>
              Delete Account
            </button>
            <div className="searchBar flex">
              <input
                type="text"
                placeholder="Search User"
                onChange={(event) => setinput(event.target.value)}
                // onChange={(event) => handleSearch(event.target.value)}
              />
              <BiSearchAlt className="icon" />
              <div className="searchHints">
                <span className="hint">
                  Search by name <br />
                </span>
                <span className="hint">Search by email</span>
              </div>
            </div>
          </div>
        </div>
        <Box m="20px">
          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            <DataGrid
              checkboxSelection
              rows={
                users.length == 0
                  ? []
                  : users.filter(
                      (i) =>
                        i?.name
                          .toLowerCase()
                          .trim()
                          .includes(input.toLowerCase()) ||
                        i?.email
                          .toLowerCase()
                          .trim()
                          .includes(input.toLowerCase())
                    )
              }
              columns={columns}
              onSelectionModelChange={handleSelectionChange}
            />
          </Box>
        </Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {error && (
          <div className="overlay">
            <div className="error-popup">
              <p>{error}</p>
              <button onClick={() => setError(false)}>Close</button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Users;
