import React, { useState, useEffect } from "react";
import { useFilePicker } from "use-file-picker";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./add_user.css";
import Select from "react-select";
import axios from "axios";
import Top from "../top section/Top";
import { stateToHTML } from "draft-js-export-html";
import { Editor } from "react-draft-wysiwyg";
import Cookies from "universal-cookie";
import { BASE_URL } from "../../../context/config";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw } from "draft-js";
import BackBtn from "../../BackBtn";
const AddUser = (props) => {
  const roles = [
    { value: "admin", label: "Admin (Career Services) " },
    { value: "systemadmin", label: "System Admin (IT Support)" },
  ];
  const cookies = new Cookies();
  const session = useState(cookies.get("sessionid"));
  const [titleFocus, setTitleFocus] = useState("input-field");
  const [title, setTitle] = useState("");
  const [descriptionFocus, setDescriptionFocus] = useState("input-field");
  const [description, setDescription] = useState("");
  const [applicationLinkFocus, setApplicationLinkFocus] =
    useState("input-field");
  const [applicationLink, setApplicationLink] = useState("");
  const [applicationEmailFocus, setApplicationEmailFocus] =
    useState("input-field");
  const [applicationEmail, setApplicationEmail] = useState("");
  const [startSalaryFocus, setStartSalaryFocus] = useState("input-field");
  const [startSalary, setStartSalary] = useState("");
  const [endSalaryFocus, setEndSalaryFocus] = useState("input-field");
  const [endSalary, setEndSalary] = useState("");
  const [createTagFocus, setCreateTagFocus] = useState("input-field");
  const [newTag, setNewTag] = useState("");
  const [createNewOfferTypeFocus, setCreateNewOfferTypeFocus] =
    useState("input-field");
  const [newOfferType, setNewOfferType] = useState("");
  const [contactNameFocus, setContactNameFocus] = useState("input-field");
  const [contactName, setContactName] = useState("");
  const [contactEmailFocus, setContactEmailFocus] = useState("input-field");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhoneFocus, setContactPhoneFocus] = useState("input-field");
  const [contactPhone, setContactPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [editorState, setEditorState] = useState("");
  const [selectedOfferType, setSelectedOfferType] = useState();
  const [selectedCompanies, setSelectedCompanies] = useState();

  const [selectedTags, setSelectedTags] = useState();
  const [tagsList, setTagList] = useState([]);
  const [companies, setCompanies] = useState(roles);
  const [offerTypes, setOfferTypes] = useState([]);
  const minHeight = "500px";
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileId, setFileId] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());

  function handleSelectCompanies(data) {
    setSelectedCompanies(data);
  }
  function handleSelectOfferType(data) {
    setSelectedOfferType(data);
  }
  function processTags(tags) {
    if (tags) {
      const arrayOfValues = tags.map((obj) => obj.label);
      return arrayOfValues;
    }
    return [];
  }

  function onTitleFocus() {
    setTitleFocus("input-field active");
  }
  function onTitleBlur() {
    if (title == "") {
      setTitleFocus("input-field");
    }
  }
  function onDescriptionFocus() {
    setDescriptionFocus("input-field active");
  }
  function onDescriptionBlur() {
    if (description == "") {
      setDescriptionFocus("input-field");
    }
  }
  function onApplicationLinkFocus() {
    setApplicationLinkFocus("input-field active");
  }
  function onApplicationLinkBlur() {
    if (applicationLink == "") {
      setApplicationLinkFocus("input-field");
    }
  }
  function onApplicationEmailFocus() {
    setApplicationEmailFocus("input-field active");
  }
  function onApplicationEmailBlur() {
    if (applicationEmail == "") {
      setApplicationEmailFocus("input-field");
    }
  }
  function onStartSalaryFocus() {
    setStartSalaryFocus("input-field active");
  }
  function onStartSalaryBlur() {
    if (startSalary == "") {
      setStartSalaryFocus("input-field");
    }
  }
  function onEndSalaryFocus() {
    setEndSalaryFocus("input-field active");
  }
  function onEndSalaryBlur() {
    if (endSalary == "") {
      setEndSalaryFocus("input-field");
    }
  }
  function onCreateTagFocus() {
    setCreateTagFocus("input-field active");
  }
  function onCreateTagBlur() {
    if (newTag == "") {
      setCreateTagFocus("input-field");
    }
  }
  function onContactNameFocus() {
    setContactNameFocus("input-field active");
  }
  function onContactNameBlur() {
    if (contactName == "") {
      setContactNameFocus("input-field");
    }
  }
  function onContactEmailFocus() {
    setContactEmailFocus("input-field active");
  }
  function onContactEmailBlur() {
    if (contactEmail == "") {
      setContactEmailFocus("input-field");
    }
  }
  function onContactPhoneFocus() {
    setContactPhoneFocus("input-field active");
  }
  function onContactPhoneBlur() {
    if (contactPhone == "") {
      setContactPhoneFocus("input-field");
    }
  }
  function onCreateOfferTypeFocus() {
    setCreateNewOfferTypeFocus("input-field active");
  }
  function onCreateOfferTypeBlur() {
    if (newOfferType == "") {
      setCreateNewOfferTypeFocus("input-field");
    }
  }

  const handlePopupClose = () => {
    setError(null);
  };

  const PaddedDiv = () => {
    const divStyle = {
      paddingTop: "20px", // Adjust the value to set the desired padding top
      paddingBottom: "20px", // Adjust the value to set the desired padding bottom
      // Add more styles here if needed
      // For example:
      // backgroundColor: '#f0f0f0',
      // color: 'blue',
      // fontSize: '18px',
    };

    return <div style={divStyle}></div>;
  };
  const uploadFile = () => {
    const fileData = new FormData();
    console.log(selectedFile);
    fileData.append("primaryemail", session[0].primaryemail);
    fileData.append("sessionid", session[0].sessionid);
    fileData.append("file", selectedFile);
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    axios
      .post(`${BASE_URL}/media/upload`, fileData, { headers })
      .then((response) => {
        setFileId(response.data);
      })
      .catch((error) => {
        console.log(error);
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getCurrentDateFormatted = (currentDate) => {
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = String(currentDate.getFullYear());

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };
  const handleAddOffer = (e) => {
    setIsLoading(true);
    if (selectedFile) {
      uploadFile();
    }

    try {
      const offerData = {
        sessionid: session[0].sessionid,
        role: selectedCompanies.value,
        firstname: description,
        primaryemail: session[0].primaryemail,
        lastname: applicationLink,
        useremail: applicationEmail,
        defaultpassword: startSalary,
        password: startSalary,
      };

      console.log(offerData);
      const headers = {
        "Content-Type": "application/json",
      };
      axios
        .post(`${BASE_URL}/account/admin/createadminaccount`, offerData, {
          headers,
        })
        .then((response) => {
          navigate("/opportunities");
        })
        .catch((error) => {
          setError("Could not add opportunity");
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };
  const getEditorData = () => {
    const raw = stateToHTML(editorState.getCurrentContent());
    return JSON.stringify(raw);
  };
  return (
    <div className="mainContent2">
      <Top />
      <div className="box-add-company">
        <div className="inner-box">
          <div className="forms-wrap-add_company-add_company">
            <div className="form sign-in-form">
              <BackBtn />
              <div className="heading">
                <h2>Add New User</h2>
              </div>
              <div className="actual-form">
                <PaddedDiv />

                <div className="dropdown-container">
                  <Select
                    options={companies}
                    placeholder="Select Role"
                    value={selectedCompanies}
                    onChange={handleSelectCompanies}
                    isSearchable={true}
                  />
                </div>
                <br></br>
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) => setDescription(event.target.value)}
                    minLength={4}
                    className={descriptionFocus}
                    onFocus={onDescriptionFocus}
                    onBlur={onDescriptionBlur}
                    autoComplete="off"
                    required
                  />
                  <label>Enter First Name</label>
                </div>
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) => setApplicationLink(event.target.value)}
                    minLength={4}
                    className={applicationLinkFocus}
                    onFocus={onApplicationLinkFocus}
                    onBlur={onApplicationLinkBlur}
                    autoComplete="off"
                    required
                  />
                  <label>Enter Last Name</label>
                </div>
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) =>
                      setApplicationEmail(event.target.value)
                    }
                    minLength={4}
                    className={applicationEmailFocus}
                    onFocus={onApplicationEmailFocus}
                    onBlur={onApplicationEmailBlur}
                    autoComplete="off"
                    required
                  />
                  <label>User Email</label>
                </div>

                <br></br>
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) => setStartSalary(event.target.value)}
                    minLength={4}
                    className={startSalaryFocus}
                    onFocus={onStartSalaryFocus}
                    onBlur={onStartSalaryBlur}
                    autoComplete="off"
                    required
                  />
                  <label>Default Password</label>
                </div>

                <PaddedDiv />

                <div className="sign-btn">
                  <input
                    type="submit"
                    onClick={handleAddOffer}
                    value="Add User"
                    className="sign-btn"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {error && (
        <div className="overlay">
          <div className="error-popup">
            <p>Can not create opportunities check inputs</p>
            <button onClick={() => setError(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddUser;
