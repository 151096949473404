import React from "react";
import { BiArrowBack } from "react-icons/bi";
import "./style.css";
import { useNavigate } from "react-router-dom";

const BackBtn = ({ text }) => {
  let navigate = useNavigate();
  return (
    <div className="back-btn__cover" onClick={() => navigate(-1)}>
      <div className="back-btn__icon-cover">
        <BiArrowBack size={16} />
      </div>
      <div className="back-btn__text">{text || "Go back"}</div>
    </div>
  );
};

export default BackBtn;
