import React, { useState, useEffect } from "react";
import { useFilePicker } from "use-file-picker";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./add_opportunity.css";
import Select from "react-select";
import Top from "../top section/Top";
import axios from "axios";
import { stateToHTML } from "draft-js-export-html";
import { Editor } from "react-draft-wysiwyg";
import Cookies from "universal-cookie";
import { BASE_URL } from "../../../context/config";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AiFillFilePdf } from "react-icons/ai";
import { AiFillFileWord } from "react-icons/ai";
import BackBtn from "../../BackBtn";
const AddOpportunity = (props) => {
  const cookies = new Cookies();
  const session = useState(cookies.get("sessionid"));
  console.log("session")
  console.log(session)
  console.log("add opportunties")
  const [titleFocus, setTitleFocus] = useState("input-field");
  const [title, setTitle] = useState("");
  const [descriptionFocus, setDescriptionFocus] = useState("input-field");
  const [description, setDescription] = useState("");
  const [applicationLinkFocus, setApplicationLinkFocus] =
    useState("input-field");
  const [applicationLink, setApplicationLink] = useState("");
  const [applicationEmailFocus, setApplicationEmailFocus] =
    useState("input-field");
  const [applicationEmail, setApplicationEmail] = useState("");
  const [startSalaryFocus, setStartSalaryFocus] = useState("input-field");
  const [startSalary, setStartSalary] = useState("");
  const [endSalaryFocus, setEndSalaryFocus] = useState("input-field");
  const [endSalary, setEndSalary] = useState("");
  const [createTagFocus, setCreateTagFocus] = useState("input-field");
  const [newTag, setNewTag] = useState("");
  const [createNewOfferTypeFocus, setCreateNewOfferTypeFocus] =
    useState("input-field");
  const [newOfferType, setNewOfferType] = useState("");
  const [contactNameFocus, setContactNameFocus] = useState("input-field");
  const [contactName, setContactName] = useState("");
  const [contactEmailFocus, setContactEmailFocus] = useState("input-field");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhoneFocus, setContactPhoneFocus] = useState("input-field");
  const [contactPhone, setContactPhone] = useState("");
  const [detailInfoFocus, setdetialInfoFocus] = useState("input-field");
  const [detailInfo, setDetailInfo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [editorState, setEditorState] = useState("");
  const [selectedOfferType, setSelectedOfferType] = useState();
  const [selectedCompanies, setSelectedCompanies] = useState();

  const [selectedTags, setSelectedTags] = useState();
  const [tagsList, setTagList] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [offerTypes, setOfferTypes] = useState([]);
  const minHeight = "500px";
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileId, setFileId] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file && file.size <= 1024 * 1024) {
      // Check if file size is less than 1 MB (1 MB = 1024 * 1024 bytes)
      // Proceed with handling the file
      // Your code here
      setSelectedFile(file);
    } else {
      alert("Please select a file smaller than 1 MB.");
      e.target.value = null; // Clear the input to allow selecting a different file
    }
  };

  function handleSelectCompanies(data) {
    setSelectedCompanies(data);
  }
  function handleSelectOfferType(data) {
    setSelectedOfferType(data);
  }
  function processTags(tags) {
    if (tags) {
      const arrayOfValues = tags.map((obj) => obj.label);
      return arrayOfValues;
    }
    return [];
  }
  function handleSelectTags(data) {
    setSelectedTags(data);
  }
  function onTitleFocus() {
    setTitleFocus("input-field active");
  }
  function onTitleBlur() {
    if (title == "") {
      setTitleFocus("input-field");
    }
  }
  function onDescriptionFocus() {
    setDescriptionFocus("input-field active");
  }
  function onDescriptionBlur() {
    if (description == "") {
      setDescriptionFocus("input-field");
    }
  }
  function onDetailInfoFocus() {
    setdetialInfoFocus("input-field active");
  }
  function onDetailInfoBlur() {
    if (description == "") {
      setdetialInfoFocus("input-field");
    }
  }
  function onApplicationLinkFocus() {
    setApplicationLinkFocus("input-field active");
  }
  function onApplicationLinkBlur() {
    if (applicationLink == "") {
      setApplicationLinkFocus("input-field");
    }
  }
  function onApplicationEmailFocus() {
    setApplicationEmailFocus("input-field active");
  }
  function onApplicationEmailBlur() {
    if (applicationEmail == "") {
      setApplicationEmailFocus("input-field");
    }
  }
  function onStartSalaryFocus() {
    setStartSalaryFocus("input-field active");
  }
  function onStartSalaryBlur() {
    if (startSalary == "") {
      setStartSalaryFocus("input-field");
    }
  }
  function onEndSalaryFocus() {
    setEndSalaryFocus("input-field active");
  }
  function onEndSalaryBlur() {
    if (endSalary == "") {
      setEndSalaryFocus("input-field");
    }
  }
  function onCreateTagFocus() {
    setCreateTagFocus("input-field active");
  }
  function onCreateTagBlur() {
    if (newTag == "") {
      setCreateTagFocus("input-field");
    }
  }
  function onContactNameFocus() {
    setContactNameFocus("input-field active");
  }
  function onContactNameBlur() {
    if (contactName == "") {
      setContactNameFocus("input-field");
    }
  }
  function onContactEmailFocus() {
    setContactEmailFocus("input-field active");
  }
  function onContactEmailBlur() {
    if (contactEmail == "") {
      setContactEmailFocus("input-field");
    }
  }
  function onContactPhoneFocus() {
    setContactPhoneFocus("input-field active");
  }
  function onContactPhoneBlur() {
    if (contactPhone == "") {
      setContactPhoneFocus("input-field");
    }
  }
  function onCreateOfferTypeFocus() {
    setCreateNewOfferTypeFocus("input-field active");
  }
  function onCreateOfferTypeBlur() {
    if (newOfferType == "") {
      setCreateNewOfferTypeFocus("input-field");
    }
  }

  const handlePopupClose = () => {
    setError(null);
  };
  useEffect(() => {
    const getOffers = async() => {
      console.log("Getting opportunties")
    setIsLoading(true);
    
    const offerData = {
      primaryemail: session[0].primaryemail,
      sessionid: session[0].sessionid,
      limit: 100,
      offset: 0,
      activeparam: 1,
    };

    const headers = {
      "Content-Type": "application/json",
    };
    
    axios
      .post(
        `${BASE_URL}/retrieve/active/opportunity/getallopportunities`,
        offerData,
        {
          headers,
        }
      )
      .then((response) => {
        const transformedData = response.data.body.map((obj) => ({
          value: obj.responsibility,
          label: obj.responsibility,
        }));
        let types = [];
        const uniqueTypes = new Set();
        for (let i = 0; i < transformedData.length; i++) {
          if (!uniqueTypes.has(transformedData[i].value)) {
            // Add the value to the set
            uniqueTypes.add(transformedData[i].value);

            // Push a new object to the tags array
            types.push({
              value: transformedData[i].value,
              label: transformedData[i].value,
            });
          }
        }

        setOfferTypes(types);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
    getOffers();
  }, []);
  useEffect(() => {
    console.log("Getting companies")
    setIsLoading(true);
    const companyData = {
      primaryemail: session[0].primaryemail,
      sessionid: session[0].sessionid,
    };

    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(`${BASE_URL}/entities/company/getallcompanyies`, companyData, {
        headers,
      })
      .then((response) => {
        const transformedData = response.data.body.map((obj) => ({
          value: obj.companyid,
          label: obj.name,
        }));
        setCompanies(transformedData);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, [null]);
  useEffect(() => {
    console.log("Getting industry tags")
    setIsLoading(true);
    const companyData = {
      primaryemail: session[0].primaryemail,
      sessionid: session[0].sessionid,
    };

    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        `${BASE_URL}/retrieve/opportunity/getdistinctindustrytags`,
        companyData,
        {
          headers,
        }
      )
      .then((response) => {
        const arrayOfObjects = response.data.map((value, index) => ({
          value: index,
          label: value,
        }));
        setTagList(arrayOfObjects);
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [null]);
  const handleCreateTag = () => {
    const arrayOfObjects = tagsList;
    arrayOfObjects.push({
      value: newTag,
      label: newTag,
    });
    const uniqueArray = Object.values(
      arrayOfObjects.reduce((uniqueObj, currentItem) => {
        uniqueObj[currentItem.value] = currentItem;
        return uniqueObj;
      }, {})
    );

    setTagList(uniqueArray);
    alert("Industry Tag Created Please Select from List below");
  };

  const handleCreateOfferType = () => {
    const arrayOfObjects = offerTypes;
    arrayOfObjects.push({
      value: newOfferType,
      label: newOfferType,
    });
    const uniqueArray = Object.values(
      arrayOfObjects.reduce((uniqueObj, currentItem) => {
        uniqueObj[currentItem.value] = currentItem;
        return uniqueObj;
      }, {})
    );

    setOfferTypes(uniqueArray);
    alert("Offer Type Created Please Select from List below");
  };
  const PaddedDiv = () => {
    const divStyle = {
      paddingTop: "20px", // Adjust the value to set the desired padding top
      paddingBottom: "20px", // Adjust the value to set the desired padding bottom
      // Add more styles here if needed
      // For example:
      // backgroundColor: '#f0f0f0',
      // color: 'blue',
      // fontSize: '18px',
    };

    return <div style={divStyle}></div>;
  };
  const uploadFile = () => {
    const fileData = new FormData();
    console.log(selectedFile);
    fileData.append("primaryemail", session[0].primaryemail);
    fileData.append("sessionid", session[0].sessionid);
    fileData.append("file", selectedFile);
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    axios
      .post(`${BASE_URL}/media/upload`, fileData, { headers })
      .then((response) => {
        const file_id = response.data;
        setFileId(response.data, add_offer(file_id.toString()));
      })
      .catch((error) => {
        console.log(error);
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getSalary = (salary) => {
    try {
      const newSalary = parseInt(salary);
      return newSalary;
    } catch (e) {
      return -1;
    }
  };
  const getCurrentDateFormatted = (currentDate) => {

    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = String(currentDate.getFullYear());

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };
  const handleAddOffer = async (e) => {
    setIsLoading(true);
    if (applicationLink == "" && applicationEmail == "") {
      setError("One of Application Link and Email must not be empty");
      setIsLoading(false);
      return null;
    }
    if (selectedFile) {
      await uploadFile();
    } else {
      add_offer("");
    }
  };
  const add_offer = (file_id) => {
    console.log(file_id);
    try {
      const offerData = {
        primaryemail: session[0].primaryemail,
        sessionid: session[0].sessionid,
        companyid: parseInt(selectedCompanies.value),
        description: description,
        education: title,
        applylink: applicationLink,
        applyemail: applicationEmail,
        applicationenddate: getCurrentDateFormatted(selectedDate),
        contactemail: contactEmail,
        contactname: contactName,
        contactphone: contactPhone,
        skillrequirement: "test",
        postedby: session[0].primaryemail,
        dateposted: getCurrentDateFormatted(new Date()),
        salarystartrange: parseInt(startSalary),
        salaryendrange: parseInt(endSalary),
        industrytags: JSON.stringify(processTags(selectedTags)),
        responsibility: selectedOfferType.value,
        fileid: file_id + "",
        jobid: 0,
        limit: 0,
        offset: 0,
        activeparam: true,
        title: title,
        additional: detailInfo,
        linktoapplication: applicationLink,
      };

      const headers = {
        "Content-Type": "application/json",
      };
      axios
        .post(`${BASE_URL}/publish/opportunity/createopportunity`, offerData, {
          headers,
        })
        .then((response) => {
          navigate("/opportunities");
        })
        .catch((error) => {
          setError("Could not add opportunity");
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (e) {
      console.log(e);
      setError("Could not add offer");
    } finally {
      setIsLoading(false);
    }
  };
  const getEditorData = () => {
    try {
      const raw = stateToHTML(editorState.getCurrentContent());
      return JSON.stringify(raw);
    } catch (e) {
      return " ";
    }
  };
  function viewFile() {
    window.open(URL.createObjectURL(selectedFile), "_blank");
  }

  function removeFile() {
    setSelectedFile(null);
    document.getElementById("fileInput").value = ""; // Reset the file input value
  }
  return (
    <div className="mainContent2">
      <Top />
      <div className="box-add-company">
        <div className="inner-box">
          <div className="forms-wrap-add_company-add_company">
            <div className="form sign-in-form">
              <BackBtn />
              <div className="heading">
                <h2>Add New Opportunity</h2>
              </div>
              <div className="actual-form">
                <PaddedDiv />
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) => setTitle(event.target.value)}
                    minLength={4}
                    className={titleFocus}
                    onFocus={onTitleFocus}
                    onBlur={onTitleBlur}
                    autoComplete="off"
                    required
                  />
                  <label>Enter Offer Title</label>
                </div>
                <div className="dropdown-container">
                  <Select
                    options={companies}
                    placeholder="Select Company"
                    value={selectedCompanies}
                    onChange={handleSelectCompanies}
                    isSearchable={true}
                  />
                </div>
                <PaddedDiv />
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) => setDescription(event.target.value)}
                    minLength={4}
                    className={descriptionFocus}
                    onFocus={onDescriptionFocus}
                    onBlur={onDescriptionBlur}
                    autoComplete="off"
                    required
                  />
                  <label>Brief Offer Description</label>
                </div>
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) => setApplicationLink(event.target.value)}
                    minLength={4}
                    className={applicationLinkFocus}
                    onFocus={onApplicationLinkFocus}
                    onBlur={onApplicationLinkBlur}
                    autoComplete="off"
                    required
                  />
                  <label>Link to Application</label>
                </div>
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) =>
                      setApplicationEmail(event.target.value)
                    }
                    minLength={4}
                    className={applicationEmailFocus}
                    onFocus={onApplicationEmailFocus}
                    onBlur={onApplicationEmailBlur}
                    autoComplete="off"
                    required
                  />
                  <label>Application Email</label>
                </div>
                <div className="input-wrap">
                  <p>Enter Deadline</p>
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    openToDate = {new Date()}
                    dateFormat="MM/dd/yyyy"
                    className="custom-date-picker"
                  />
                </div>
                <br></br>
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) => setStartSalary(event.target.value)}
                    minLength={4}
                    className={startSalaryFocus}
                    onFocus={onStartSalaryFocus}
                    onBlur={onStartSalaryBlur}
                    autoComplete="off"
                    required
                  />
                  <label>Starting Salary($) or (Negotiable)</label>
                </div>
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) => setEndSalary(event.target.value)}
                    minLength={4}
                    className={endSalaryFocus}
                    onFocus={onEndSalaryFocus}
                    onBlur={onEndSalaryBlur}
                    autoComplete="off"
                    required
                  />
                  <label>Ending Salary($) or (Negotiable)</label>
                </div>
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) => setNewTag(event.target.value)}
                    minLength={4}
                    className={createTagFocus}
                    onFocus={onCreateTagFocus}
                    onBlur={onCreateTagBlur}
                    autoComplete="off"
                  />
                  <label>New Tag</label>
                </div>
                <div className="input-wrap">
                  <button className="btn" onClick={handleCreateTag}>
                    Create Tag
                  </button>
                </div>
                <div className="input-wrap">
                  <Select
                    options={tagsList}
                    placeholder="Industry Tags"
                    value={selectedTags}
                    onChange={handleSelectTags}
                    isSearchable={true}
                    isMulti
                  />
                </div>
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) => setNewOfferType(event.target.value)}
                    minLength={4}
                    className={createNewOfferTypeFocus}
                    onFocus={onCreateOfferTypeFocus}
                    onBlur={onCreateOfferTypeBlur}
                    autoComplete="off"
                  />
                  <label>Create a New Offer Type</label>
                </div>
                <div className="input-wrap">
                  <button className="btn" onClick={handleCreateOfferType}>
                    Create Offer Type
                  </button>
                </div>
                <div className="input-wrap">
                  <Select
                    options={offerTypes}
                    placeholder="Choose Offer Type"
                    value={selectedOfferType}
                    onChange={handleSelectOfferType}
                    isSearchable={true}
                  />
                </div>
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) => setContactName(event.target.value)}
                    minLength={4}
                    className={contactNameFocus}
                    onFocus={onContactNameFocus}
                    onBlur={onContactNameBlur}
                    autoComplete="off"
                    required
                  />
                  <label>Contact Person Name</label>
                </div>
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) => setContactEmail(event.target.value)}
                    minLength={4}
                    className={contactEmailFocus}
                    onFocus={onContactEmailFocus}
                    onBlur={onContactEmailBlur}
                    autoComplete="off"
                    required
                  />
                  <label>Contact Person Email</label>
                </div>
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) => setContactPhone(event.target.value)}
                    minLength={4}
                    className={contactPhoneFocus}
                    onFocus={onContactPhoneFocus}
                    onBlur={onContactPhoneBlur}
                    autoComplete="off"
                    required
                  />
                  <label>Contact Person Phone</label>
                </div>
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) => setDetailInfo(event.target.value)}
                    minLength={4}
                    className={detailInfoFocus}
                    onFocus={onDetailInfoFocus}
                    onBlur={onDetailInfoBlur}
                    autoComplete="off"
                    required
                  />
                  <label>Enter Detail Information</label>
                </div>
                <div>
                  <h3>Upload File</h3>
                  <div>
                    <input
                      type="file"
                      className="btn"
                      accept=".docx,.doc,.pdf"
                      onChange={handleFileChange}
                      id="fileInput"
                    />

                    {selectedFile && (
                      <div className="flex row">
                        <p>Selected File: {selectedFile.name}</p>

                        <button className="btn" onClick={viewFile}>
                          {" "}
                          <span>
                            {selectedFile.type === "application/pdf" && (
                              <AiFillFilePdf className="icon" />
                            )}
                            {selectedFile.type ===
                              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
                              <AiFillFileWord />
                            )}
                          </span>{" "}
                          View File
                        </button>
                        <button className="btn" onClick={removeFile}>
                          <span className="material-icons" />
                          Remove File
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <PaddedDiv />
                <div className="sign-btn">
                  <input
                    type="submit"
                    onClick={handleAddOffer}
                    value="Add Offer"
                    className="sign-btn"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {error && (
        <div className="overlay">
          <div className="error-popup">
            <p>Can not create opportunities check inputs</p>
            <button onClick={() => setError(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddOpportunity;
