import React, { useState, useEffect } from "react";
import "./analytics.css";
import Top from "../top section/Top";
import axios from "axios";
import { BASE_URL } from "../../../context/config";
import Cookies from "universal-cookie";
import TagChart from "../../charts/BarChart";
import TagPieChart from "../../charts/Piechart";
import { Link, useNavigate, useLocation } from "react-router-dom";

const Analytics = () => {
  const data = [
    {
      id: 1,
      title: "Registered users",
      count: 0,
    },
    {
      id: 2,
      title: "Pending invites",
      count: 0,
    },
    {
      id: 3,
      title: "Accepted Invites.",
      count: 0,
    },
    {
      id: 4,
      title: "Companies Added.",
      count: 0,
    },
  ];
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setcount] = useState(0);
  const [pending, setpending] = useState(0);
  const [accepted, setaccepted] = useState(0);
  const [opportunitiescount, setopportunitiescount] = useState(0);
  const cookies = new Cookies();
  const session = useState(cookies.get("sessionid"));
  const navigate = useNavigate();
  const [userrole, setuserrole] = useState("");
  const [frequencyResult, setfrequencyResult] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        // admin roles
        if (session[0]?.role == "admin") {
          setuserrole(session[0]?.role);
        } else {
          const userData = {
            primaryemail: session[0]?.primaryemail,
            sessionid: session[0]?.sessionid,
            startdate: "",
            enddate: "",
            limit: 1000,
            offset: 0,
          };
          const res = await axios.post(
            `${BASE_URL}/account/admin/getallusers`,
            userData
          );
          setcount(res?.data?.meta.count);
          setpending(
            res?.data?.body.filter((i) => i?.acceptancestatus == false).length
          );

          setaccepted(
            res?.data?.body.filter((i) => i?.acceptancestatus == true).length
          );
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const companyData = {
          primaryemail: session[0].primaryemail,
          sessionid: session[0].sessionid,
        };
        const headers = {
          "Content-Type": "application/json",
        };

        const res = await axios.post(
          `${BASE_URL}/entities/company/getallcompanyies`,
          companyData,
          { headers }
        );

        setopportunitiescount(res?.data.meta?.count);
      } catch (err) {
        console.log(err.message);
      }
    })();
  }, []);

  function countFrequency(arr) {
    const frequencyMap = {};

    // Count the frequency of each value
    arr.forEach((value) => {
      frequencyMap[value] = (frequencyMap[value] || 0) + 1;
    });

    // Convert the frequency map to an array of objects
    const result = Object.entries(frequencyMap).map(([value, count]) => ({
      value,
      count,
    }));

    return result;
  }

  useEffect(() => {
    setIsLoading(true);
    const companyData = {
      primaryemail: session[0].primaryemail,
      sessionid: session[0].sessionid,
    };
    const headers = {
      "Content-Type": "application/json",
    };

    const offerData = {
      primaryemail: session[0].primaryemail,
      sessionid: session[0].sessionid,
      limit: 100,
      offset: 0,
    };

    axios
      .post(
        `${BASE_URL}/retrieve/active/opportunity/getallopportunities`,
        offerData,
        {
          headers,
        }
      )
      .then((response) => {
        const transformedData = response.data.body.map((obj) => ({
          tags: JSON.parse(obj.industrytags).join(","),
        }));
        let tags = [];
        const uniqueTags = new Set();
        for (let i = 0; i < transformedData.length; i++) {
          // let oneTag = transformedData[i].tags.split(",");
          tags.push(transformedData[i].tags);
        }
        // console.log(tags, "tags");
        setfrequencyResult(countFrequency(tags));
      })
      .catch((error) => {
        console.log(error);
        setError("Could not all offers");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="mainContent">
      <Top />
      {userrole == "admin" ? (
        <div className="analytic_cover">
          {/* <div className="analytic_card">
            <div className="analytic_title">Registered users</div>
            <div className="analytic_value">{count}</div>
          </div>
          <div className="analytic_card">
            <div className="analytic_title">Pending invites</div>
            <div className="analytic_value">{pending}</div>
          </div>
          <div className="analytic_card">
            <div className="analytic_title">Accepted Invites.</div>
            <div className="analytic_value">{accepted}</div>
          </div> */}
          <div className="analytic_card">
            <div className="analytic_title">Companies Added</div>
            <div className="analytic_value">{opportunitiescount}</div>
          </div>
        </div>
      ) : (
        <div className="analytic_cover">
          <div className="analytic_card">
            <div className="analytic_title">Registered users</div>
            <div className="analytic_value">{count}</div>
          </div>
          <div className="analytic_card">
            <div className="analytic_title">Pending invites</div>
            <div className="analytic_value">{pending}</div>
          </div>
          <div className="analytic_card">
            <div className="analytic_title">Accepted Invites.</div>
            <div className="analytic_value">{accepted}</div>
          </div>
          <div className="analytic_card">
            <div className="analytic_title">Companies Added</div>
            <div className="analytic_value">{opportunitiescount}</div>
          </div>
        </div>
      )}
      <div className="chart-container">
        <div
          className="chart-cover"
          style={{
            display: "none",
          }}
        >
          <div className="">
            <div className="chart-title">Companies</div>
          </div>
          <div
            className=""
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 470,
            }}
          >
            <TagChart />
          </div>
        </div>
        <div className="chart-cover">
          <div className="">
            <div className="chart-title">Tags of industries.</div>
          </div>
          <div
            className=""
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 450,
            }}
          >
            <TagPieChart data={frequencyResult} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
