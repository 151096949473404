import React from 'react'
import './opportunities.css'
import Top from '../top section/Top'
import Listing from './listing/Listing'
const Opportunities = () => {
  
    return (
      <div className='mainContent'>
        <Top/>
        <Listing/>
      </div>
    )
  
}

export default Opportunities