import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "../auth.css";
import logo_map from "../../../assets/log_map.png";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { BASE_URL } from "../../../context/config.js";
import { useAuth } from "../../../context/auth_context.js";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
export default function ResetPasswordExternal(props) {
  const location = useLocation();

  const [primaryemail, setprimaryemail] = useState("");

  const [currentPassword, setCurrentPassword] = useState("");
  const [currentPasswordFocus, setCurrentPasswordFocus] =
    useState("input-field");
  const [passFocus, setPassFocus] = useState("input-field");
  const [password, setPassword] = useState("");
  const { id } = useParams();
  const [confirmPassFocus, setConfirmPassFocus] = useState("input-field");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  function onCurrentPasswordFocus() {
    setCurrentPasswordFocus("input-field active");
  }
  function onPassFocus() {
    setPassFocus("input-field active");
  }
  function onCurrentPasswordBlur() {
    if (currentPassword == "") {
      setCurrentPasswordFocus("input-field");
    }
  }
  function onPassBlur() {
    if (password == "") {
      setPassFocus("input-field");
    }
  }
  function onConfirmPassFocus() {
    setConfirmPassFocus("input-field active");
  }
  function onConfirmPassBlur() {
    if (confirmPassword == "") {
      setConfirmPassFocus("input-field");
    }
  }

  const handleReset = () => {
    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(
        `${BASE_URL}/auth/resetpasswordforforgotpassword`,
        {
          newpassword: password,
          primaryemail,
          resetkey: id,
        },
        {
          headers,
        }
      )
      .then((response) => {
        toast.success("Password reset was succesfull.");
        console.log(response);
        navigate("/login");
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.response);
          return;
        }
        // navigate("/login");
        toast.error(error.message);
        setError("Could not reset password");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div className="main">
      <div className="box">
        <div className="inner-box">
          <div className="forms-wrap">
            <div className="form sign-in-form">
              <div className="logo">
                <img src={logo_map} alt="CMU-Africa" />
              </div>
              <div className="heading">
                <h2>Opportunities Board</h2>
              </div>
              <div className="heading">
                <h2>Reset Password</h2>
              </div>

              <div className="actual-form">
                <div className="input-wrap">
                  <input
                    type="email"
                    onChange={(event) => setprimaryemail(event.target.value)}
                    minLength={4}
                    className={currentPasswordFocus}
                    onFocus={onCurrentPasswordFocus}
                    onBlur={onCurrentPasswordBlur}
                    autoComplete="off"
                    required
                  />
                  <label>Email address</label>
                </div>
                <div className="input-wrap">
                  <input
                    type="password"
                    onChange={(event) => setPassword(event.target.value)}
                    minLength={4}
                    className={passFocus}
                    onFocus={onPassFocus}
                    onBlur={onPassBlur}
                    autoComplete="off"
                    required
                  />
                  <label>New Password</label>
                </div>
                <div
                  className="input-wrap"
                  style={{
                    display: "none",
                  }}
                >
                  <input
                    type="password"
                    onChange={(event) => setConfirmPassword(event.target.value)}
                    minLength={4}
                    className={confirmPassFocus}
                    onFocus={onConfirmPassFocus}
                    onBlur={onConfirmPassBlur}
                    autoComplete="off"
                    required
                  />
                  <label>Confirm Password</label>
                </div>
                <div className="sign-btn">
                  <input
                    type="submit"
                    onClick={handleReset}
                    value="Reset Password"
                    className="sign-btn"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="carousel"></div>
        </div>
      </div>
      <Helmet>
        <script src="./login.js" type="text/jsx" />
      </Helmet>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {error && (
        <div className="overlay">
          <div className="error-popup">
            <p>Invalid Credentials</p>
            <button onClick={() => setError(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}
