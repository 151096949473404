import React from 'react'
import './favorites.css'
import Top from '../top section/Top'
import Listing from './listing/Listing'

const Favorites = () => {
  return (
    <div className='mainContent'>
      <Top/>
      <Listing/>
    </div>
  )
}

export default Favorites