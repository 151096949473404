import React, { useState, useEffect } from "react";
import { DateField } from "@mui/x-date-pickers";
import axios from "axios";
import "./edit_company.css";
import Select from "react-select";
import Cookies from "universal-cookie";
import { BASE_URL } from "../../../context/config";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useLocation } from "react-router-dom";
import Top from "../top section/Top";
import BackBtn from "../../BackBtn";
const AddCompany = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state;

  const [company, setCompany] = useState(data);
  const cookies = new Cookies();
  const session = useState(cookies.get("sessionid"));
  const [companyFocus, setCompanyFocus] = useState("input-field active");
  const [companyName, setCompanyName] = useState(company.name);
  const [cname, setCname] = useState(companyName);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [emailFocus, setEmailFocus] = useState("input-field active");
  const [companyEmail, setCompanyEmail] = useState(company.email);
  const [phoneFocus, setPhoneFocus] = useState("input-field active");
  const [companyPhone, setCompanyPhone] = useState(company.phone);
  const [industryFocus, setIndustryFocus] = useState("input-field active");
  const [companyIndustry, setCompanyIndustry] = useState(company.industry);
  const [cityFocus, setCityFocus] = useState("input-field active");
  const [companyCity, setCompanyCity] = useState(company.city);
  const [streetFocus, setStreetFocus] = useState("input-field active");
  const [companyStreet, setCompanyStreet] = useState(company.street);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  function handleSelectCountry(country) {
    setSelectedCountry(country);
  }
  function onCompanyFocus() {
    setCompanyFocus("input-field active");
  }

  function onCompanyBlur() {
    if (companyName == "") {
      setCompanyFocus("input-field");
    }
  }

  function onCompanyEmailFocus() {
    setEmailFocus("input-field active");
  }

  function onCompanyEmailBlur() {
    if (companyEmail == "") {
      setEmailFocus("input-field");
    }
  }
  function onCompanyPhoneFocus() {
    setPhoneFocus("input-field active");
  }

  function onCompanyPhoneBlur() {
    if (companyPhone == "") {
      setPhoneFocus("input-field");
    }
  }
  function onCompanyIndustryFocus() {
    setIndustryFocus("input-field active");
  }

  function onCompanyIndustryBlur() {
    if (companyIndustry == "") {
      setIndustryFocus("input-field");
    }
  }

  function onCompanyCityFocus() {
    setCityFocus("input-field active");
  }

  function onCompanyCityBlur() {
    if (companyCity == "") {
      setCityFocus("input-field");
    }
  }
  function onCompanyStreetFocus() {
    setStreetFocus("input-field active");
  }

  function onCompanyStreetBlur() {
    if (companyStreet == "") {
      setStreetFocus("input-field");
    }
  }
  const handlePopupClose = () => {
    setError(null);
  };

  useEffect(() => {
    // Define the API endpoint to get the list of countries
    const apiEndpoint = "https://restcountries.com/v3.1/all";

    // Fetch the data from the API using Axios
    axios
      .get(apiEndpoint)
      .then((response) => {
        // Handle successful response and update the 'countries' state
        setCountries(response.data);
        const countriesData = response.data.map((country) => ({
          value: country.cca2,
          label: country.name.common,
        }));
        const old_country = countriesData.filter(
          (obj) => obj.label == company.country
        );
        console.log(old_country);
        setSelectedCountry(old_country[0]);
        setCountries(countriesData);
      })
      .catch((error) => {
        // Handle error if the request fails
        console.error("Error fetching countries:", error);
      });
  }, []);
  const handleEditCompany = (e) => {
    setIsLoading(true);
    const companyData = {
      primaryemail: session[0].primaryemail,
      sessionid: session[0].sessionid,
      city: companyCity,
      country: selectedCountry.label,
      industry: companyIndustry,
      street: companyStreet,
      companyemail: companyEmail,
      companyname: companyName,
      companyphone: companyPhone,
      companyid: company.id,
    };

    const headers = {
      "Content-Type": "application/json",
    };
    console.log(companyData);
    axios
      .post(`${BASE_URL}/entities/company/editcompanyinfo`, companyData, {
        headers,
      })
      .then((response) => {
        navigate("/companies");
      })
      .catch((error) => {
        setError("Could not update company");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div className="mainContent2">
      <Top />
      <div className="mainContent2">
        <div className="box">
          <div className="inner-box">
            <div className="forms-wrap-handleEditCompany">
              <div className="form sign-in-form">
                <div className="">
                  <BackBtn />
                </div>
                <div className="heading">
                  <h2>Edit Company ( {cname} ) </h2>
                </div>
                <br></br>
                <div className="actual-form">
                  <div className="input-wrap">
                    <input
                      type="text"
                      value={companyName}
                      onChange={(event) => setCompanyName(event.target.value)}
                      minLength={4}
                      className={companyFocus}
                      onFocus={onCompanyFocus}
                      onBlur={onCompanyBlur}
                      autoComplete="off"
                      required
                    />
                    <label>Company Name</label>
                  </div>
                  <div className="input-wrap">
                    <input
                      type="text"
                      value={companyEmail}
                      onChange={(event) => setCompanyEmail(event.target.value)}
                      minLength={4}
                      className={emailFocus}
                      onFocus={onCompanyEmailFocus}
                      onBlur={onCompanyEmailBlur}
                      autoComplete="off"
                      required
                    />
                    <label>Company Email</label>
                  </div>
                  <div className="input-wrap">
                    <input
                      type="text"
                      value={companyPhone}
                      onChange={(event) => setCompanyPhone(event.target.value)}
                      minLength={4}
                      className={phoneFocus}
                      onFocus={onCompanyPhoneFocus}
                      onBlur={onCompanyPhoneBlur}
                      autoComplete="off"
                      required
                    />
                    <label>Company Phone</label>
                  </div>
                  <div className="input-wrap">
                    <input
                      type="text"
                      value={companyIndustry}
                      onChange={(event) =>
                        setCompanyIndustry(event.target.value)
                      }
                      minLength={4}
                      className={industryFocus}
                      onFocus={onCompanyIndustryFocus}
                      onBlur={onCompanyIndustryBlur}
                      autoComplete="off"
                      required
                    />
                    <label>Company Industry</label>
                  </div>
                  <div className="input-wrap">
                    <div className="input-wrap">
                      <Select
                        options={countries}
                        placeholder="Country"
                        value={selectedCountry}
                        onChange={handleSelectCountry}
                        isSearchable={true}
                      />
                    </div>
                  </div>
                  <div className="input-wrap">
                    <input
                      type="text"
                      value={companyCity}
                      onChange={(event) => setCompanyCity(event.target.value)}
                      minLength={4}
                      className={cityFocus}
                      onFocus={onCompanyCityFocus}
                      onBlur={onCompanyCityBlur}
                      autoComplete="off"
                      required
                    />
                    <label>Company City</label>
                  </div>

                  <div className="sign-btn">
                    <input
                      type="submit"
                      onClick={handleEditCompany}
                      value="Update Company"
                      className="sign-btn"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {error && (
        <div className="overlay">
          <div className="error-popup">
            <p>Error Editing Company</p>
            <button onClick={() => setError(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddCompany;
