import React, { useState, useEffect } from "react";
import Login from "./components/auth/login/Login";
import { Routes, Route, Navigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AddUser from "./components/body/add_user/add_opportunity";
import "./app.css";
import SignUp from "./components/auth/register/Register";
import PrivateRoutes from "./private_route";
import Body from "./components/body/Body";
import Apply from "./components/body/apply/Apply";
import ResetPassword from "./components/auth/reset_password/ResetPassword";
import ForgetPassword from "./components/auth/forget_password/ForgetPassword";
import SetPassword from "./components/auth/set_password/SetPassword";
import { AuthProvider } from "./context/auth_context";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Toaster } from "react-hot-toast";
import Companies from "./components/body/companies/Companies";
import AddCompany from "./components/body/add_company/add_company";
import EditCompany from "./components/body/edit_company/edit_company";
import Opportunities from "./components/body/opportunities/Opportunites";
import AddOpportunity from "./components/body/add_opportunity/add_opportunity";
import EditOpportunity from "./components/body/edit_opportunity/edit_opportunity";
import Users from "./components/body/users/Users";
import Analytics from "./components/body/analytics/analytics";
import Favorites from "./components/body/favorites/Favorites";
import Applied from "./components/body/applied/Applied";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import ResetPasswordExternal from "./components/auth/reset_password_external/ResetPasswordExternal";
function App({ children }) {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const session = useState(cookies.get("sessionid"));

  useEffect(() => {
    try {
      if (!session) {
        navigate("/login");
      }
    } catch (error) {
      navigate("/login");
    }
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route element={<Body />} path="/board" />
          <Route element={<Favorites />} path="/favorites" />
          <Route element={<AddUser />} path="/add_user" />
          <Route path="/" element={<Navigate to="/board" />} />
          <Route element={<Body />} path="/templates" />
          <Route element={<Opportunities />} path="/opportunities" />
          <Route element={<AddOpportunity />} path="/add_opportunity" />
          <Route element={<Analytics />} path="/analytics" />
          <Route element={<Companies />} path="/companies" />
          <Route element={<Apply />} path="/apply" />
          <Route element={<Users />} path="/users" />
          <Route element={<Applied />} path="/applied" />
          <Route element={<AddCompany />} path="/add_company" />
          <Route element={<EditCompany />} path="/edit_company" />
          <Route element={<EditOpportunity />} path="/edit_opportunity" />
        </Route>
        <Route element={<SignUp />} path="/register/*" />
        <Route element={<Login />} path="/login" />
        <Route element={<ResetPassword />} path="/reset_password" />
        <Route element={<ResetPasswordExternal />} path="/forgotpassword/:id" />
        <Route element={<ForgetPassword />} path="/forget_password" />
        <Route element={<SetPassword />} path="/set_password/*" />
      </Routes>
      <Toaster />
    </LocalizationProvider>
  );
}

export default App;
