import React, { useState, useEffect } from "react";
import "./body.css";
import Top from "./top section/Top";
import Listing from "./listing/Listing";
import Cookies from "universal-cookie";
import { useNavigate, Outlet } from "react-router-dom";

const Body = () => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const session = useState(cookies.get("sessionid"));
  useEffect(() => {
    try {
      console.log(session);
      if (!session) {
        navigate("/login");
      }
    } catch (error) {
      navigate("/login");
    }
  }, [session]);
  return (
    <div className="mainContent">
      <Top />
      <Listing />
    </div>
  );
};

export default Body;
