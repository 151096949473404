import React, { useState, useContext, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";

import "../auth.css";
import logo_map from "../../../assets/log_map.png";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { BASE_URL } from "../../../context/config.js";
import { useAuth } from "../../../context/auth_context.js";
import { useNavigate } from "react-router-dom";
export default function Login() {
  const cookies = new Cookies();
  const [useFocus, setUseFocus] = useState("input-field active");
  const [passFocus, setPassFocus] = useState("input-field active");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [userInfo, setUserInfo] = useState(cookies.get("sessionID"));
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  function onUserFocus() {
    setUseFocus("input-field active");
  }
  function onPassFocus() {
    setPassFocus("input-field active");
  }
  function onUserBlur() {
    if (userName == "") {
      setUseFocus("input-field");
    }
  }
  function onPassBlur() {
    if (password == "") {
      setPassFocus("input-field");
    }
  }
  function handleLogin ()  {
    if (userName != null && password != null) {
      login(userName, password);
    }
  }
  const login = (username, password) => {
    setIsLoading(true);
    const userData = {
      primaryemail: username,
      password: password,
    };
    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .post(`${BASE_URL}/auth/single/login`, userData, { headers })
      .then((response) => {
        if (response.data != null) {
        }
        if (response.data["response"] == "reset password") {
          navigate("/reset_password", {
            state: {
              primaryemail: username,
              sessionid: response.data["sessionid"],
            },
          });
          return;
        }
        const user = {
          role: response.data["role"],
          firstname: response.data["firstname"],
          lastname: response.data["lastname"],
          primaryemail: username,
          sessionid: response.data["sessionid"],
        };
         storeUserInfo(user);

        // Call storeUserInfo inside the .then block after successful login
      })
      .catch((error) => {
        setError("Invalid Credentials");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const storeUserInfo = async (user) => {
    if (user === null) {
    } else {
      await cookies.set("sessionid", user);
      navigate("/board");
      window.history.pushState(null, null, window.location.href);
      window.addEventListener("popstate", () => {
        window.history.pushState(null, null, window.location.href);
      });
    }
  };

  const handlePopupClose = () => {
    setError(null);
  };
  return (
    <div className="main">
      <div className="box">
        <div className="inner-box">
          <div className="heading"></div>
          <div className="forms-wrap ">
            <div className="form sign-in-form">
              <div className="logo">
                <img src={logo_map} alt="CMU-Africa" />
              </div>
              <div className="heading">
                <h3>Career Services</h3>
                <h2>Opportunities Board </h2>
              </div>
              <div className="heading">
                <h2>Login</h2>
              </div>
              <div className="actual-form">
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) => setUserName(event.target.value)}
                    minLength={4}
                    className={useFocus}
                    onFocus={onUserFocus}
                    onBlur={onUserBlur}
                    autoComplete="off"
                    required
                  />
                  <label>Enter Email</label>
                </div>
                <div className="input-wrap">
                  <input
                    type="password"
                    onChange={(event) => setPassword(event.target.value)}
                    minLength={4}
                    className={passFocus}
                    onFocus={onPassFocus}
                    onBlur={onPassBlur}
                    autoComplete="off"
                    required
                  />
                  <label>Password</label>
                </div>
                <div className="sign-btn">
                  <input
                    type="submit"
                    onClick={handleLogin}
                    value="Sign In"
                    className="sign-btn"
                  />
                </div>
                <p className="text">
                  Forgotten your password or your login details?
                  <a href="#">
                    <Link
                      to="../forget_password"
                      style={{ color: "blue", textDecoration: "underline" }}
                    >
                      Click here
                    </Link>
                  </a>{" "}
                  to reset password
                  {/* signing in */}
                </p>
              </div>
            </div>
          </div>
          <div className="carousel"></div>
        </div>
      </div>
      <Helmet>
        <script src="./login.js" type="text/jsx" />
      </Helmet>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {error && (
        <div className="overlay">
          <div className="error-popup">
            <p>Invalid Credentials</p>
            <button onClick={() => setError(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}
