import React from "react";
import "./sidebar.css";
import { BsQuestionCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/afica_logo.png";
const SideBar = (props) => {
  const history = useNavigate();

  const itSupport = "africa-int-tech@andrew.cmu.edu";
  const mailToITSupport = `mailto:${itSupport}?subject=${encodeURIComponent(
    "Technical Support Opportunities Board"
  )}`;
  const studentSupport = "africa-careerservices@andrew.cmu.edu";
  const mailToStudentSupport = `mailto:${studentSupport}?subject=${encodeURIComponent(
    "Student Support Opportunities Board"
  )}`;
  const handleLogoClick = () => {
    // Navigate to the homepage
    history("/");
  };
  return (
    <div className="sideBar ">
      <div className="logoDiv flex" onClick={handleLogoClick}>
        <img src={logo} alt="CMU Logo" />
      </div>
      <div
        className=""
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          className="menuDiv"
          style={{
            marginTop: 20,
          }}
        >
          <h3 className="divTitle">QUICK MENU</h3>
          <ul className="menuLists grid">
            {props.sideData.map((val, key) => {
              return (
                <li key={key} className="listItem">
                  <a
                    href={val.link}
                    className="menuLink flex "
                    id={window.location.pathname === val.link ? "active" : ""}
                  >
                    {val.icon}
                    <span className="smallText">{val.title}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="sidebar_bottom">
          <div className="sideBarCard">
            <BsQuestionCircle className="icon" />
            <div className="cardContent">
              <div className="circle1"></div>
              <div className="circle2"></div>
              <h3>Help Center</h3>
              <p>
                Having trouble using this opportunities board, please contact us
                for more questions{" "}
              </p>
              <p>
                <a href={mailToITSupport}>Technical Support</a> <span></span>
              </p>
              <p>
                <a href={mailToStudentSupport}>General Inquiries</a>{" "}
                <span></span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
