import React from "react";
import {
  MdOutlineDashboard,
  MdAppRegistration,
  MdBusiness,
  MdAnalytics,
  MdTipsAndUpdates,
  MdPerson2,
} from "react-icons/md";
import { MdFavorite } from "react-icons/md";
import { IoMdDocument } from "react-icons/io";
import { BsQuestionCircle } from "react-icons/bs";
export const sideBarDataUser = [
  {
    title: "Board",
    icon: <MdOutlineDashboard className="icon" />,
    link: "/board",
  },
  {
    title: "Favorites",
    icon: <MdFavorite className="icon" />,
    link: "/favorites",
  },
  {
    title: "Applied",
    icon: <MdAppRegistration className="icon" />,
    link: "/applied",
  },
];
export const sideBarManager = [
  {
    title: "Board",
    icon: <MdOutlineDashboard className="icon" />,
    link: "/board",
  },
  {
    title: "Companies",
    icon: <MdBusiness className="icon" />,
    link: "/companies",
  },
  {
    title: "Opportunities",
    icon: <MdTipsAndUpdates className="icon" />,
    link: "/opportunities",
  },
  {
    title: "Analytics",
    icon: <MdAnalytics className="icon" />,
    link: "/analytics",
  },
];
export const sideBarAdmin = [
  {
    title: "Board",
    icon: <MdOutlineDashboard className="icon" />,
    link: "/board",
  },
  {
    title: "Companies",
    icon: <MdBusiness className="icon" />,
    link: "/companies",
  },
  {
    title: "Opportunities",
    icon: <MdTipsAndUpdates className="icon" />,
    link: "/opportunities",
  },
  {
    title: "Analytics",
    icon: <MdAnalytics className="icon" />,
    link: "/analytics",
  },
  {
    title: "Users",
    icon: <MdPerson2 className="icon" />,
    link: "/users",
  },
];
