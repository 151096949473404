// export const BASE_URL = "http://localhost:8083/";
const href = window.location.href.toString();
let url = "";

// console.log(href, "---href");
if (href.includes("local")) {
  url = "https://opportunities.africa.local.cmu.edu/api/";
  //   url = "http://opportunities.africa.cmu.edu/:8083";
} else {
  url = "https://opportunities.africa.cmu.edu/api/";
}
export const BASE_URL = url;
//console.log(BASE_URL);
export const oppo_rootadmin_username = "oppoportal@andrew.cmu.edu";
export const oppo_rootadmin_password = "opportunity@cmua";
