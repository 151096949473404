import React, { useState, useEffect } from "react";
import "./listing.css";
import { BiSearchAlt } from "react-icons/bi";
import { AiFillHeart } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import axios from "axios";
import Select from "react-select";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { BASE_URL } from "../../../context/config";
const Listing = () => {
  const [offers, setData] = useState([]);
  const [allOffers, setAllOffers] = useState([]);
  const cookies = new Cookies();
  const session = useState(cookies.get("sessionid"));
  const role = session[0].role;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedLocations, setSelectedLocations] = useState();
  const [selectedStipend, setSelectedStipend] = useState();
  const [selectedCompanies, setSelectedCompanies] = useState();
  const [selectedTypes, setSelectedTypes] = useState();
  const [selectedTags, setSelectedTags] = useState();
  const [tagsList, setTagLists] = useState([]);
  const [locations, setLocations] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const [types, setTypes] = useState([]);
  const [companies, setCompanies] = useState([]);
  const navigate = useNavigate();
  const stipend = [
    { value: "0-100", label: "$0 - $100" },
    { value: "100-250", label: "$100 - $250" },
    { value: "250-500", label: "$250 - $500" },
    { value: "500-1000", label: "$500 - $1000" },
    { value: "1000-2000", label: "$1000 - $2000" },
    { value: "2000-20000", label: "> $2000" },
  ];
  function handleSelectTags(data) {
    setSelectedTags(data);
    if (data.length != 0) {
      const value = data.map((item) => item.value);

      const toFilter = allOffers.filter((item) =>
        offers.some((bitem) => bitem.id === item.id)
      );
      const filteredData = toFilter.filter((offer) =>
        offer.tags.split(",").some((element) => value.includes(element))
      );
      setData(filteredData);
    } else {
      setData(allOffers);
    }
  }
  function handleSelectTypes(data) {
    setSelectedTypes(data);
    if (data.length != 0) {
      const value = data.map((item) => item.value);

      const toFilter = allOffers.filter((item) =>
        offers.some((bitem) => bitem.id === item.id)
      );
      const filteredData = toFilter.filter(
        (offer) =>
          value.join(",").toLowerCase().includes(offer.type.toLowerCase())
        // You can add more properties to search in if needed
      );
      setData(filteredData);
    } else {
      setData(allOffers);
    }
  }
  function handleSelectCompanies(data) {
    setSelectedCompanies(data);
    if (data.length != 0) {
      const value = data.map((item) => item.value);

      const toFilter = allOffers.filter((item) =>
        offers.some((bitem) => bitem.id === item.id)
      );
      const filteredData = toFilter.filter(
        (offer) =>
          value.join(",").toLowerCase().includes(offer.company.toLowerCase())
        // You can add more properties to search in if needed
      );
      setData(filteredData);
    } else {
      setData(allOffers);
    }
  }
  function handleSelectStipend(data) {
    setSelectedStipend(data);
    if (data.length != 0) {
      let value = data.map((item) => item.value.split("-"));
      value = value.flat();
      value = value.map((str) => parseInt(str));
      const toFilter = allOffers.filter((item) =>
        offers.some((bitem) => bitem.id === item.id)
      );
      const min = Math.min(...value);
      const max = Math.max(...value);

      const filteredData = toFilter.filter(
        (offer) =>
          offer.salarystartrange.toString().toLowerCase() == "negotiable" ||
          (offer.salarystartrange >= min && offer.salaryendrange <= max)
      );
      setData(filteredData);
    } else {
      setData(allOffers);
    }
  }
  function handleSelectLocations(data) {
    setSelectedLocations(data);
    if (data.length != 0) {
      const value = data.map((item) => item.value);

      const toFilter = allOffers.filter((item) =>
        offers.some((bitem) => bitem.id === item.id)
      );
      const filteredData = toFilter.filter(
        (offer) =>
          value.join(",").toLowerCase().includes(offer.location.toLowerCase())
        // You can add more properties to search in if needed
      );
      setData(filteredData);
    } else {
      setData(allOffers);
    }
  }

  function handleClear() {
    setSearchValue("");
    handleSelectLocations([]);
    handleSelectTypes([]);
    handleSelectStipend([]);
    handleSelectTags([]);
    handleSelectCompanies([]);
  }

  useEffect(() => {
    setIsLoading(true);
    const companyData = {
      primaryemail: session[0].primaryemail,
      sessionid: session[0].sessionid,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(`${BASE_URL}/entities/company/getallcompanyies`, companyData, {
        headers,
      })
      .then((response) => {
        const companies = response.data.body.map((obj) => ({
          id: obj.companyid,
          location: obj.city + ", " + obj.country,
          name: obj.name,
        }));
        const offerData = {
          primaryemail: session[0].primaryemail,
          sessionid: session[0].sessionid,
          limit: 100,
          offset: 0,
        };
        let saved_jobs = [];
        axios
          .post(
            `${BASE_URL}/saveforlater/opportunity/getsavedjobs`,
            offerData,
            {
              headers,
            }
          )
          .then((response) => {
            saved_jobs = response.data.body;
          })
          .catch((err) => {
            console.log(err);
          });
        axios
          .post(
            `${BASE_URL}/retrieve/active/opportunity/getallopportunities`,
            offerData,
            {
              headers,
            }
          )
          .then((response) => {
            // console.log(response.data);
            const transformedData = response.data.body.map((obj) => ({
              id: obj.jobid,
              title: obj.education,
              short_description: obj.description,
              type: obj.responsibility,
              tags: JSON.parse(obj.industrytags).join(","),
              application_link: obj.applylink ? obj.applylink : obj.applyemail,
              fileid: obj.fileid,
              contactemail: obj.contactemail,
              contactname: obj.contactname,
              likeColor: saved_jobs.includes(obj.jobid) ? "#f29f9f" : "#000000",
              location: companies.find((newObj) => newObj.id == obj.companyid)
                ? companies.find((newObj) => newObj.id == obj.companyid)
                    .location
                : "Company Deleted",
              company: companies.find((newObj) => newObj.id == obj.companyid)
                ? companies.find((newObj) => newObj.id == obj.companyid).name
                : "Company Deleted",
              stipend:
                (obj.salarystartrange == 0
                  ? "Negotiable"
                  : obj.salarystartrange) +
                " - $" +
                (obj.salaryendrange == -0 ? "Negotiable" : obj.salaryendrange),
              salarystartrange:
                obj.salarystartrange == 0 ? "Negotiable" : obj.salarystartrange,
              salaryendrange:
                obj.salaryendrange == 0 ? "Negotiable" : obj.salaryendrange,
              "end date": obj.applicationenddate.split(" ")[0],
              detailedInfo: obj.additional,
            }));
            let tags = [];
            const uniqueTags = new Set();
            for (let i = 0; i < transformedData.length; i++) {
              let oneTag = transformedData[i].tags.split(",");
              // Iterate through the oneTag array
              for (let j = 0; j < oneTag.length; j++) {
                // Check if the tag value is not already in the set
                if (!uniqueTags.has(oneTag[j])) {
                  // Add the value to the set
                  uniqueTags.add(oneTag[j]);

                  // Push a new object to the tags array
                  tags.push({
                    value: oneTag[j],
                    label: oneTag[j],
                  });
                }
              }
            }
            let types = [];
            const uniqueTypes = new Set();
            for (let i = 0; i < transformedData.length; i++) {
              if (!uniqueTypes.has(transformedData[i].type)) {
                // Add the value to the set
                uniqueTypes.add(transformedData[i].type);

                // Push a new object to the tags array
                types.push({
                  value: transformedData[i].type,
                  label: transformedData[i].type,
                });
              }
            }
            let location = [];
            const uniqeLocations = new Set();
            for (let i = 0; i < transformedData.length; i++) {
              if (!uniqeLocations.has(transformedData[i].location)) {
                // Add the value to the set
                uniqeLocations.add(transformedData[i].location);

                // Push a new object to the tags array
                location.push({
                  value: transformedData[i].location,
                  label: transformedData[i].location,
                });
              }
            }
            let companys = [];
            const uniqueCompanies = new Set();
            for (let i = 0; i < transformedData.length; i++) {
              if (!uniqueCompanies.has(transformedData[i].company)) {
                // Add the value to the set
                uniqueCompanies.add(transformedData[i].company);

                // Push a new object to the tags array
                companys.push({
                  value: transformedData[i].company,
                  label: transformedData[i].company,
                });
              }
            }
            setCompanies(companys);
            setLocations(location);
            setTagLists(tags);
            setTypes(types);
            setData(transformedData);
            setAllOffers(transformedData);
          })
          .catch((error) => {
            console.log(error);
            setError("Could not all offers");
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch((error) => {
        console.log(error);
        setError("An error occurred please refresh the page");
        if (error.response.status == 401) {
          navigate("/login");
          setError("Session Expired");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  const handleSearch = (value) => {
    // If the search input is empty, set the companies state to allCompanies
    setSearchValue(value);
    if (value.trim() === "") {
      setData(allOffers);
    } else {
      // Filter the companies based on the search input
      const filteredOffers = allOffers.filter(
        (offer) => offer.title.toLowerCase().includes(value.toLowerCase())
        // You can add more properties to search in if needed
      );

      setData(filteredOffers);
    }
  };
  function getTags(allTags) {
    const items = allTags.split(",");
    return items.map((item, index) => (
      <div key={index} className="tags">
        <p
          style={{
            backgroundColor: "#f29f9f",
            padding: "5px",
            borderRadius: 5,
            fontSize: 12,
          }}
        >
          {" "}
          {item}
        </p>
      </div>
    ));
  }
  function updateSate(event, index) {
    const jobData = {
      primaryemail: session[0].primaryemail,
      sessionid: session[0].sessionid,
      jobid: index,
    };
    const headers = {
      "Content-Type": "application/json",
    };

    const newState = offers.map((obj) => {
      console.log(index);

      if (obj.id === index) {
        if (obj.likeColor === "#f29f9f") {
          axios
            .post(`${BASE_URL}/saveforlater/opportunity/deletejob`, jobData, {
              headers,
            })
            .then((response) => {
              console.log(response.data);
            })
            .catch((error) => {
              console.log(error);
              setError("Could not all offers");
            })
            .finally(() => {
              setIsLoading(false);
            });
          return { ...obj, likeColor: "#000000" };
        } else {
          axios
            .post(`${BASE_URL}/saveforlater/opportunity/savejob`, jobData, {
              headers,
            })
            .then((response) => {
              console.log(response.data);
            })
            .catch((error) => {
              console.log(error);
              setError("Could not all offers");
            })
            .finally(() => {
              setIsLoading(false);
            });
          return { ...obj, likeColor: "#f29f9f" };
        }
      }

      return obj;
    });

    setData(newState);
  }
  function Activity() {
    return (
      <div className="filterCard grid">
        <div className="heading flex">
          <h2>Filter</h2>
          <button className="btn" onClick={handleClear}>
            Clear All
          </button>
        </div>
        <div className="option">
          <div className="label">
            <h6 className="filter-labels">Industry Tags</h6>
          </div>
          <div className="dropdown-container">
            <Select
              options={tagsList}
              placeholder="Select Industry Tags"
              value={selectedTags}
              onChange={handleSelectTags}
              isSearchable={true}
              isMulti
            />
          </div>
        </div>
        <div className="option">
          <div className="label">
            <h6 className="filter-labels">Type</h6>
          </div>
          <div className="dropdown-container">
            <Select
              options={types}
              placeholder="Select Offer Type"
              value={selectedTypes}
              onChange={handleSelectTypes}
              isSearchable={true}
              isMulti
            />
          </div>
        </div>
        <div className="option">
          <div className="label">
            <h6 className="filter-labels">Location</h6>
          </div>
          <div className="dropdown-container">
            <Select
              options={locations}
              placeholder="Select Locations"
              value={selectedLocations}
              onChange={handleSelectLocations}
              isSearchable={true}
              isMulti
            />
          </div>
        </div>
        <div className="option">
          <div className="label">
            <h6 className="filter-labels">Salary</h6>
          </div>
          <div className="dropdown-container">
            <Select
              options={stipend}
              placeholder="Select Salary Range"
              value={selectedStipend}
              onChange={handleSelectStipend}
              isSearchable={true}
              isMulti
            />
          </div>
        </div>
        <div className="option">
          <div className="label">
            <h6 className="filter-labels">Companies</h6>
          </div>
          <div className="dropdown-container">
            <Select
              options={companies}
              placeholder="Select Companies"
              value={selectedCompanies}
              onChange={handleSelectCompanies}
              isSearchable={true}
              isMulti
            />
          </div>
        </div>
      </div>
    );
  }

  function buildOffers(offers) {
    return (
      <div className="secContainer flex">
        <div className="singleItem">
          <div className="details">
            {/* <div className="details grid"> */}
            <div className="heading flex">
              <Link
                to="../apply"
                state={{ data: offers }}
                style={{
                  marginBottom: 10,
                  fontWeight: "normal",
                  color: "#b54b4b",
                  textDecoration: "underline",
                }}
              >
                <div className="title" title="Click to show more details">
                  <h5>{offers.title}</h5>
                </div>
              </Link>
              <div className="tools flex">
                {role.includes("admin") ? (
                  <div></div>
                ) : (
                  <button className="fav-btutton btn">
                    <AiFillHeart
                      className="icon"
                      style={{ color: offers.likeColor }}
                      onClick={(event) => updateSate(event, offers.id)}
                    />
                  </button>
                )}
              </div>
            </div>
            <div className="title">
              <div className="comTitle">
                <h6>Company: {offers.company}</h6>
              </div>
            </div>
            <div className="">
              <p className="rule-title">Job Description</p>
              <hr className="horizontal-rule" />
            </div>

            <div className="breifDescription flex">
              <p>{offers.short_description}</p>
            </div>
            <div className="properties flex">
              <p>
                <GoLocation size={16} />
              </p>
              <p className="custom-address_title">{offers.location}</p>
              <p className="custom-address_title">
                ${offers.stipend} / Monthly
              </p>
              <p className="custom-address_title">
                {" "}
                | Deadline: {offers["end date"]}
              </p>
            </div>
            <div className="allTags flex custom-address_title">
              {getTags(offers.tags)}
            </div>
            <div>
              <p
                style={{
                  backgroundColor: "#9FF2AD",
                  width: "fit-content",
                  padding: "5px",
                  borderRadius: 5,
                  fontSize: 12,
                }}
              >
                {offers.type}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bottom flex">
      <div className="listingSection">
        <div className="heading flex list-box">
          <h1>Opportunities</h1>
          <div className="tools flex">
            <div className="searchBar flex">
              <input
                type="text"
                placeholder="Search Opportunities"
                value={searchValue}
                onChange={(event) => handleSearch(event.target.value)}
              />
              <BiSearchAlt className="icon" />
              <div className="searchHints">
                <span className="hint">
                  Search by title <br />
                </span>
              </div>
            </div>
          </div>
        </div>
        {offers.map((item, index) => buildOffers(item))}
      </div>
      <Activity />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {error && (
        <div className="overlay">
          <div className="error-popup">
            <p>Error Occured Please Refresh</p>
            <button onClick={() => setError(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Listing;
