import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import '../auth.css'
import logo_map from '../../../assets/log_map.png'
import axios from 'axios';
import Cookies from 'universal-cookie';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {BASE_URL} from '../../../context/config.js'
import {useAuth} from '../../../context/auth_context.js'
import { useNavigate } from 'react-router-dom';
export default function Signup() {

    const [useFocus, setUseFocus] = useState('input-field');
    const [passFocus, setPassFocus] = useState('input-field');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassFocus, setConfirmPassFocus] = useState('input-field');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null);
    const navigate = useNavigate();  
    const href = window.location.href.toString();
    console.log(href)
    // Split the hash string by '/' to get an array of parts
    const parts = href.split('/');

    // Get the last part of the route (which is "register" in this case)
    const lastPart = parts[parts.length-1];

    const invitationId = lastPart
    

    function onUserFocus(){
        setUseFocus('input-field active');
    }
    function onPassFocus(){
        setPassFocus('input-field active');
    }
    function onUserBlur(){
       if (userName==""){
        setUseFocus("input-field")
       }
    }
    function onPassBlur(){
        if (password==""){
            setPassFocus("input-field")
           }
    }
    function onConfirmPassFocus(){
      setConfirmPassFocus('input-field active');
  }
  function onConfirmPassBlur(){
     if (confirmPassword==""){
      setConfirmPassFocus("input-field")
     }
  }
  const handleRegister = () => {

    const registerPayload = {
        primaryemail:userName,
        password:password,
        inviteid:invitationId
    }
    console.log(registerPayload)
    const headers = {
        "Content-Type": "application/json",
      };
      axios
        .post(`${BASE_URL}/onboarding/accounts/activateinvitation`, registerPayload, {
          headers,
        })
        .then((response) => {
          console.log(response)
          navigate('/login')
        })
        .catch((error) => {
          console.log(error)
          setError("Could not load User");
        })
        .finally(() => {
  
          setIsLoading(false);
        });   
    
  }
  return (
    <div className='main'>
        <div className="box">
        <div className="inner-box">
            <div className="forms-wrap"> 
                <div className="form sign-in-form">
                    <div className="logo">
                        <img src={logo_map} alt="CMU-Africa"/>
                        
                    </div>
                    <div className="heading">
                        <h2>Opportunities Board</h2>
                    </div>

                    <div className="actual-form">
                        <div className="input-wrap">
                            <input type='text'  onChange={(event) =>setUserName(event.target.value) }  minLength={4} className={useFocus} onFocus={onUserFocus} onBlur={onUserBlur} autoComplete='off' required/>
                            <label >
                            Enter Email
                            </label>
                        </div>
                        <div className="input-wrap">
                            <input type='password' onChange={(event) =>setPassword(event.target.value) } minLength={4} className={passFocus} onFocus={onPassFocus}  onBlur={onPassBlur} autoComplete='off' required/>
                            <label >
                            Password
                            </label>
                        </div>
                        <div className="input-wrap">
                            <input type='password' onChange={(event) =>setConfirmPassword(event.target.value) } minLength={4} className={confirmPassFocus} onFocus={onConfirmPassFocus}  onBlur={onConfirmPassBlur} autoComplete='off' required/>
                            <label >
                            Confirm Password
                            </label>
                        </div>
                        <div className="sign-btn">
                            <input type='submit' value="Register" onClick={handleRegister} className='sign-btn'/>
                        </div>
                       
                    </div>
                </div>
            </div>
            <div className="carousel"></div>
        </div>
        </div>
        <Helmet>
              <script src="./login.js" type="text/jsx" />
        </Helmet>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
            >
        <CircularProgress color="inherit" />
        
      </Backdrop>
      {error && (
         
         <div className="overlay">
         <div className="error-popup">
           <p>Invalid Credentials</p>
           <button onClick={() => setError(false)}>Close</button>
         </div>
       </div>
     )}
   </div>
  );
  
}
