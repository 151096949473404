import React from 'react'
import '../body.css'
import Top from '../top section/Top'
import Listing from './Listing'

const Applied = () => {
  return (
    <div className='mainContent'>
      <Top/>
      <Listing/>
    </div>
  )
}

export default Applied