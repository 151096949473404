import "./usermodal.css";
import { GiHouseKeys } from "react-icons/gi";
import { MdOutlineManageAccounts } from "react-icons/md";
import { MdOutlineSupervisorAccount } from "react-icons/md";
import { MdClose } from "react-icons/md";
import { BASE_URL } from "../../context/config";
import axios from "axios";
import Cookies from "universal-cookie";
import { useState } from "react";
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";
import { IoIosArrowRoundBack } from "react-icons/io";
import Button from "../Button";
import toast, { Toaster } from "react-hot-toast";

const UserModal = ({ modal, setmodal, data }) => {
  const [tabstate, settabstate] = useState(1);
  const [loading, setloading] = useState(false);
  const cookies = new Cookies();
  const session = useState(cookies.get("sessionid"));
  const [eye, seteye] = useState(false);
  const [password, setpassword] = useState("");
  const [role, setrole] = useState("");

  const payload = {
    callerid: session[0].primaryemail,
    sessionid: session[0].sessionid,
  };

  const updatePassword = async () => {
    try {
      toast.success("Feature not available.");
      console.log();
      setloading(true);
      console.log(data, "data response");
      const res = await axios.post(
        `${BASE_URL}/account/admin/overridepassword`,
        {
          primaryemail: session[0].primaryemail,
          sessionid: session[0].sessionid,
          useremail: data?.email,
          password,
        }
      );

      setloading(false);
      console.log(...res);
    } catch (err) {
      setloading(false);

      console.log(err);
    }
  };

  const handleAccount = async () => {
    try {
    } catch (err) {
      console.log(err.message);
    }
    if (data?.activestatus) {
      // deactivate
      axios
        .post(`${BASE_URL}/onboarding/deactivateaccount`, payload)
        .then((response) => {})
        .catch((err) => console.log(err));
    } else {
      // active
    }
  };
  return (
    <div className="backdrop">
      <div className="modal-container animate__animated animate__fadeInUp">
        {tabstate == 1 ? (
          <>
            {/* options */}
            <div className="modal-top">
              <div className="modal-title">
                Select the action you would like to perform on{" "}
                <span
                  style={{
                    color: "#ba4f4f",
                  }}
                >
                  {data?.name}'s
                </span>{" "}
                account
              </div>
              <div className="modal-close-icon" onClick={() => setmodal(false)}>
                <MdClose size={20} />
              </div>
            </div>
            <div className="modal-cover">
              <div className="modal-entry" onClick={() => settabstate(2)}>
                <div className="modal-icon">
                  <GiHouseKeys />
                </div>
                <div className="modal-text">Reset pasword</div>
              </div>
              <div className="modal-entry">
                <div className="modal-icon">
                  <MdOutlineManageAccounts />
                </div>
                <div className="modal-text">Suspend account</div>
              </div>
              <div className="modal-entry" onClick={() => settabstate(3)}>
                <div className="modal-icon">
                  <MdOutlineSupervisorAccount />
                </div>
                <div className="modal-text">Assign Role</div>
              </div>
            </div>
          </>
        ) : tabstate == 2 ? (
          <>
            {/* Reset password */}
            <div className="modal-top">
              <div className="modal-title">
                Reset{" "}
                <span
                  style={{
                    color: "#ba4f4f",
                  }}
                >
                  {data?.name}'s
                </span>{" "}
                password
              </div>
              <div className="modal-close-icon" onClick={() => settabstate(1)}>
                <IoIosArrowRoundBack size={22} />
              </div>
            </div>
            {/* <div className="modal-cover"> */}
            <div className="input-cover" style={{}}>
              <input
                value={password}
                onChange={(e) => setpassword(e.target.value)}
                className="input-form"
                type={eye ? "text" : "password"}
                placeholder="Enter new password"
              />
              <div className="input-icon" onClick={() => seteye(!eye)}>
                {eye ? <IoEyeOutline /> : <IoEyeOffOutline />}
              </div>
            </div>
            <Button text={"Update"} loading={loading} event={updatePassword} />
            {/* </div> */}
          </>
        ) : (
          <>
            {/* Assign role */}{" "}
            <div className="modal-top">
              <div className="modal-title">
                Update{" "}
                <span
                  style={{
                    color: "#ba4f4f",
                  }}
                >
                  {data?.name}'s
                </span>{" "}
                role
              </div>
              <div className="modal-close-icon" onClick={() => settabstate(1)}>
                <IoIosArrowRoundBack size={22} />
              </div>
            </div>
            {/* <div className="modal-cover"> */}
            <select className="select-cover">
              <option value={"admin"}>Admin</option>
              <option value={"sysdamin"}>System admin</option>
            </select>
            <Button text={"Update"} loading={loading} />
            {/* </div> */}
          </>
        )}
      </div>
    </div>
  );
};

export default UserModal;
