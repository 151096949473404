import React, { useState, useEffect } from "react";
import { useFilePicker } from "use-file-picker";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { DateField } from "@mui/x-date-pickers";
import "./edit_opportunity.css";
import Top from "../top section/Top";
import Select from "react-select";
import { AiFillFilePdf } from "react-icons/ai";
import { AiFillFileWord } from "react-icons/ai";
import axios from "axios";
import { Editor } from "react-draft-wysiwyg";
import Cookies from "universal-cookie";
import { BASE_URL } from "../../../context/config";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useLocation } from "react-router-dom";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw } from "draft-js";
const EditOpportunity = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const {data} = location.state
  const cookies = new Cookies();
  const session = useState(cookies.get("sessionid"));
  
  const [title, setTitle] = useState(data.title);
  const [titleFocus, setTitleFocus] =  useState(title ? "input-field active": "input-field")
  const [description, setDescription] = useState(data.short_description?data.short_description:"");
  const [descriptionFocus, setDescriptionFocus] = useState( description ? "input-field active" : "input-field");
  const [applicationLink, setApplicationLink] = useState("");
  const [applicationLinkFocus, setApplicationLinkFocus] = useState(applicationLink ? "input-field active" : "input-field");
  const [applicationEmail, setApplicationEmail] = useState(data.application_link);
  const [applicationEmailFocus, setApplicationEmailFocus] = useState(applicationEmail ? "input-field active" : "input-field");
  const [startSalary, setStartSalary] = useState(data.salarystartrange==0?"Negotiable":data.salarystartrange); 
  const [startSalaryFocus, setStartSalaryFocus] = useState(startSalary ? "input-field active" : "input-field");
  const [endSalary, setEndSalary] = useState(data.salaryendrange==0? "Negotiable":data.salaryendrange); 
  const [endSalaryFocus, setEndSalaryFocus] = useState(endSalary ? "input-field active" : "input-field");
  const [createTagFocus, setCreateTagFocus] = useState("input-field");
  const [newTag, setNewTag] = useState("");
  const [createNewOfferTypeFocus, setCreateNewOfferTypeFocus] = useState("input-field");
  const [newOfferType, setNewOfferType] = useState("");
  const [contactName, setContactName] =useState(data.contactname?data.contactname:""); 
  const [contactNameFocus, setContactNameFocus] = useState(contactName ? "input-field active" : "input-field");
  const [contactEmail, setContactEmail] = useState(data.contactemail ?data.contactemail:""); 
  const [contactEmailFocus, setContactEmailFocus] = useState(contactEmail ? "input-field active" : "input-field");
  const [contactPhone, setContactPhone] = useState(data.contactphone?data.contactphone:""); 
  const [contactPhoneFocus, setContactPhoneFocus] = useState(contactPhone ? "input-field active" : "input-field");
  const [selectedDate, setSelectedDate] = useState(new Date(data['end date']));

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [jobid, setJobId] = useState(data.id);

  const [detailInfoFocus, setdetialInfoFocus] = useState("input-field active");
  const [detailInfo, setDetailInfo] = useState(data.detailedInfo);
  const [selectedOfferType, setSelectedOfferType] = useState(null);
  const [selectedCompanies, setSelectedCompanies] = useState(null);
  
  const [selectedTags, setSelectedTags] = useState();
  const [tagsList, setTagList] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [offerTypes, setOfferTypes] = useState([]);
  const minHeight = '500px';
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileId, setFileId] = useState("")
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(data['end date']);
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setLoad(true)
  };
  const [load, setLoad] = useState(false)
  const handleDownload = async (file_id) => {

 
    try {
      const response =  await axios.get(`${BASE_URL}/media/download/${file_id}`, {
        responseType: 'blob', // Set the response type to 'blob'
      });
  
      // Create a download link
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      if (selectedFile.name.includes('pdf')){
        link.setAttribute('download', 'file.pdf');
      }
      // Set the desired filename
      document.body.appendChild(link);
  
      // Trigger the download
      link.click();
  
      // Clean up the object URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
    }
    
  }
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  function handleSelectCompanies(data) {
    
    setSelectedCompanies(data);
  }
  function handleSelectOfferType(data) {
    console.log(data)
    setSelectedOfferType(data);
  }
  function processTags(tags){
    if (tags){
        const arrayOfValues = tags.map(obj => obj.value);
        return arrayOfValues
    }
    return []
  }
  function viewFile() {
    
    if (load){
      window.open(URL.createObjectURL(selectedFile), '_blank');
    }
    else {
      
      handleDownload(data.fileid)
    }
    
  }
  
  function removeFile() {
    setSelectedFile(null);
    setFileId("")
    if (load){
      document.getElementById("fileInput").value = ''; // Reset the file input value
      setFileId("")
    }

  }
  function handleSelectTags(data) {
    console.log(data)
    setSelectedTags(data);
  }
  function onTitleFocus() {
    setTitleFocus("input-field active");
  }
  function onTitleBlur() {
    if (title == "") {
      setTitleFocus("input-field");
    }
  }
  function onDescriptionFocus() {
    setDescriptionFocus("input-field active");
  }
  function onDescriptionBlur() {
    if (description == "") {
      setDescriptionFocus("input-field");
    }
  }
  function onApplicationLinkFocus() {
    setApplicationLinkFocus("input-field active");
  }
  function onApplicationLinkBlur() {
    if (applicationLink == "") {
      setApplicationLinkFocus("input-field");
    }
  }
  function onApplicationEmailFocus() {
    setApplicationEmailFocus("input-field active");
  }
  function onApplicationEmailBlur() {
    if (applicationEmail == "") {
      setApplicationEmailFocus("input-field");
    }
  }
  function onDetailInfoFocus() {
    setdetialInfoFocus("input-field active");
  }
  function onDetailInfoBlur() {
    if (description == "") {
      setdetialInfoFocus("input-field");
    }
  }
  function onStartSalaryFocus() {
    setStartSalaryFocus("input-field active");
  }
  function onStartSalaryBlur() {
    if (startSalary == "") {
      setStartSalaryFocus("input-field");
    }
  }
  function onEndSalaryFocus() {
    setEndSalaryFocus("input-field active");
  }
  function onEndSalaryBlur() {
    if (endSalary == "") {
      setEndSalaryFocus("input-field");
    }
  }
  function onCreateTagFocus() {
    setCreateTagFocus("input-field active");
  }
  function onCreateTagBlur() {
    if (newTag == "") {
        setCreateTagFocus("input-field");
    }
  }
  function onContactNameFocus() {
    setContactNameFocus("input-field active");
  }
  function onContactNameBlur() {
    if (contactName == "") {
        setContactNameFocus("input-field");
    }
  }
  function onContactEmailFocus() {
    setContactEmailFocus("input-field active");
  }
  function onContactEmailBlur() {
    if (contactEmail == "") {
        setContactEmailFocus("input-field");
    }
  }
  function onContactPhoneFocus() {
    setContactPhoneFocus("input-field active");
  }
  function onContactPhoneBlur() {
    if (contactPhone == "") {
        setContactPhoneFocus("input-field");
    }
  }
  function onCreateOfferTypeFocus() {
    setCreateNewOfferTypeFocus("input-field active");
  }
  function onCreateOfferTypeBlur() {
    if (newOfferType == "") {
        setCreateNewOfferTypeFocus("input-field");
    }
  }
  
  function areObjectsEqual(obj1, obj2) {
    return obj1.value === obj2.value && obj1.label === obj2.label;
  }
  function removeDuplicates(arr, comparator) {
    return arr.filter((item, index) => {
      return arr.findIndex((obj) => comparator(obj, item)) === index;
    });
  }

  const handlePopupClose = () => {
    setError(null);
  };
  useEffect(() => {
    setIsLoading(true);
    try {
      const companyData = {
        primaryemail: session[0].primaryemail,
        sessionid: session[0].sessionid,
        limit: 100,
        offset: 0,
        activeparam: true
      };
  
      const headers = {
        "Content-Type": "application/json",
      };
      console.log(companyData)
      axios
        .post(`${BASE_URL}/retrieve/active/opportunity/getallopportunities`, companyData, {
          headers,
        })
        .then((response) => {
          const transformedData = response.data.body.map((obj) => ({
            value: obj.responsibility,
            label: obj.responsibility,
          }));
          let myOffer = {}
          let types = []
              const uniqueTypes = new Set();
              for (let i=0; i<transformedData.length; i++){
                if (!uniqueTypes.has(transformedData[i].value)) {
                  // Add the value to the set
                  uniqueTypes.add(transformedData[i].value);
  
                  // Push a new object to the tags array
                  if (transformedData[i].value==data.type){
                  
                    myOffer = {
                      "value":transformedData[i].value,
                      "label":transformedData[i].value
                    }
                  }
                  types.push({
                    "value":transformedData[i].value,
                    "label":transformedData[i].value
                  }) ;
                }
                    
              }
         
          setOfferTypes(types)
          setSelectedOfferType(myOffer)
        })
        .catch((error) => {
          
          console.log(error)
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    catch (error){
      setError(true)
    }
  }, [null]);
  useEffect(() => {
    try{
      setIsLoading(true);
    const companyData = {
      primaryemail: session[0].primaryemail,
      sessionid: session[0].sessionid,
    };

    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(`${BASE_URL}/entities/company/getallcompanyies`, companyData, {
        headers,
      })
      .then((response) => {
        const all_companies = response.data.body
        let myCompanies = []
        let selectedCom = ""
        for (let i =0; i<all_companies.length; i++){
          myCompanies.push({
            value:all_companies[i].companyid,
            label:all_companies[i].name
          })
          if(all_companies[i].name==data.company){
            selectedCom = {
              value:all_companies[i].companyid,
              label:all_companies[i].name}
          }
        }
        setSelectedCompanies(selectedCom)
        setCompanies(myCompanies);
      })
      .catch((error) => {
        
        setError("Could not load company");
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
    catch (error) {
      setError(true)
    }
  }, [null]);
  useEffect(() => {
    setIsLoading(true);
    if (data.fileid!=""){
      const companyData = {
        primaryemail: session[0].primaryemail,
        sessionid: session[0].sessionid,
        fileid: data.fileid
      };
  
      const headers = {
        "Content-Type": "application/json",
      };
      
      axios
        .post(`${BASE_URL}media/properties/getfilename`, companyData, {
          headers,
        })
        .then((response) => {
          
          const filename = response.data.filename 
          setSelectedFile({name: filename})
          setLoad(false)
          
        })
        .catch((error) => {
          
          setError("Could not tags");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    
  }, [null]);
  useEffect(() => {
    setIsLoading(true);
    const companyData = {
      primaryemail: session[0].primaryemail,
      sessionid: session[0].sessionid,
      activeparam: true
    };

    const headers = {
      "Content-Type": "application/json",
    };
    const opTags = data.tags.split(',')
    axios
      .post(`${BASE_URL}/retrieve/opportunity/getdistinctindustrytags`, companyData, {
        headers,
      })
      .then((response) => {
        let my_tags = []
        for (let i =0; i<opTags.length; i++){
          my_tags.push({
            value:opTags[i],
            label: opTags[i]
          })
        }
        setSelectedTags(my_tags)
        const arrayOfObjects = response.data.map((value, index) => ({
            value: value,
            label: value,
          }));
          
        setTagList(arrayOfObjects)
      })
      .catch((error) => {
        
        setError("Could not tags");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [null]);
  const handleCreateTag = () => {
  
    const arrayOfObjects = tagsList
    const uniqueArray = Object.values(arrayOfObjects.reduce((uniqueObj, currentItem) => {
      uniqueObj[currentItem.value] = currentItem;
      return uniqueObj;
    }, {}));
    alert("Industry Tag Created Please Select from List below");
    setTagList(uniqueArray)
  }
  const handleCreateOfferType = () => {
    if (newOfferType.trim()==""){
      alert("Cannot add empty offer type");
      return
    }
    const arrayOfObjects = offerTypes
    arrayOfObjects.push({
      value:newOfferType,
      label: newOfferType
    })
    const uniqueArray = Object.values(arrayOfObjects.reduce((uniqueObj, currentItem) => {
      uniqueObj[currentItem.value] = currentItem;
      return uniqueObj;
    }, {}));
    alert("Offer Type Created Please Select from List below");
    setOfferTypes(uniqueArray)
  }
  const PaddedDiv = () => {
    const divStyle = {
      paddingTop: '20px', // Adjust the value to set the desired padding top
      paddingBottom: '20px', // Adjust the value to set the desired padding bottom
      // Add more styles here if needed
      // For example:
      // backgroundColor: '#f0f0f0',
      // color: 'blue',
      // fontSize: '18px',
    };
  
    return <div style={divStyle}></div>;
  };
  const uploadFile = () => {
    const fileData = new FormData()
    fileData.append('primaryemail', session[0].primaryemail)
    fileData.append('sessionid', session[0].sessionid)
    fileData.append('file', selectedFile)
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      axios.post(`${BASE_URL}/media/upload`, fileData, {headers})
        .then(response => {
          const file_id = response.data
          setFileId(response.data, editoffer(file_id.toString()))
        })
        .catch(error => {
          console.log(error)
            setError(true);
        }).finally(()=> {
            setIsLoading(false)
        });
  }
  const getCurrentDateFormatted = (currentDate) => {
   
    console.log(currentDate)
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = String(currentDate.getFullYear());
  
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };
  const editoffer = (file_id) => {
    try{
      const offerData = {
        primaryemail: session[0].primaryemail,
        sessionid: session[0].sessionid,
        companyid: parseInt(selectedCompanies.value),
        description: description,
        education: title,
        applylink:applicationLink,
        applyemail: applicationEmail,
        applicationenddate: getCurrentDateFormatted(new Date(endDate)),
        contactemail:contactEmail,
        contactname:contactName,
        contactphone: contactPhone,
        skillrequirement: "test",
        postedby:session[0].primaryemail,
        dateposted:getCurrentDateFormatted(new Date()),
        salarystartrange:parseFloat(startSalary),
        salaryendrange:parseFloat(endSalary),
        industrytags:JSON.stringify(processTags(selectedTags)),
        responsibility:selectedOfferType.value,
        fileid:file_id+'',
        jobid:jobid,
        limit:0,
        offset:0,
        activeparam:true,
        title:title,
        additional:detailInfo,
        linktoapplication:applicationLink
      };
        console.log(offerData)
        const headers = {
          "Content-Type": "application/json",
        };
        axios.post(`${BASE_URL}/publish/opportunity/editopportunity`, offerData, {headers})
          .then(response => {
              navigate('/opportunities')
            
          })
          .catch(error => {
            console.log(error)
              setError("Could not add company");
              
          }).finally(()=> {
              setIsLoading(false)
          });
  }
  catch(e){
   console.log(e)
   setError(true)
  }
  finally{
      setIsLoading(false)
  }
  }
  const handleAddOffer = async (e) => {
    
    setIsLoading(true)
    if (applicationLink =="" && applicationEmail == ""){
      setError("One of Application Link and Email must not be empty")
      setIsLoading(false)
      return null
    }
    if (selectedFile){
        await uploadFile();
    }
    else {
      editoffer("")
    } 
    
    
      
}

  return (
    <div className='mainContent2'>
    <Top/>
      <div className="box-add-company">
        <div className="inner-box">
          <div className="forms-wrap-add_company-add_company">
            <div className="form sign-in-form">
              <div className="heading">
                <h2>Edit Opportunity ({data.title})</h2>
              </div>

              <div className="actual-form">
              <PaddedDiv/>
              <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) => setTitle(event.target.value)}
                    minLength={4}
                    className={titleFocus}
                    onFocus={onTitleFocus}
                    value = {title}
                    onBlur={onTitleBlur}
                    autoComplete="off"
                    required
                  />
                  <label>Enter Offer Title</label>
                </div>
                <div className="dropdown-container">
                  <Select
                    options={companies}
                    placeholder="Select Company"
                    value={selectedCompanies}
                    onChange={handleSelectCompanies}
                    isSearchable={true}
                  />
                </div>
                <PaddedDiv/>
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) => setDescription(event.target.value)}
                    minLength={4}
                    value={description}
                    className={descriptionFocus}
                    onFocus={onDescriptionFocus}
                    onBlur={onDescriptionBlur}
                    autoComplete="off"
                    required
                  />
                  <label>Brief Offer Description</label>
                </div>
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) => setApplicationLink(event.target.value)}
                    minLength={4}
                    value={applicationLink}
                    className={applicationLinkFocus}
                    onFocus={onApplicationLinkFocus}
                    onBlur={onApplicationLinkBlur}
                    autoComplete="off"
                    required
                  />
                  <label>Link to Application</label>
                </div>
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) => setApplicationEmail(event.target.value)}
                    minLength={4}
                    value={applicationEmail}
                    className={applicationEmailFocus}
                    onFocus={onApplicationEmailFocus}
                    onBlur={onApplicationEmailBlur}
                    autoComplete="off"
                    required
                  />
                  <label>Application Email</label>
                </div>
                <br></br>
                <div className="input-wrap">
                  
                  <p>Enter Deadline</p>
                  <br></br>
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="MM/dd/yyyy"
                    className="custom-date-picker"
                    openToDate = {new Date()}
                  />
                </div>
                <br></br>
                <br></br>
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) => setStartSalary(event.target.value)}
                    minLength={4}
                    value = {startSalary}
                    className={startSalaryFocus}
                    onFocus={onStartSalaryFocus}
                    onBlur={onStartSalaryBlur}
                    autoComplete="off"
                    required
                  />
                  <label>Starting Salary($) or (Negotiable)</label>
                </div>
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) => setEndSalary(event.target.value)}
                    minLength={4}
                    value={endSalary}
                    className={endSalaryFocus}
                    onFocus={onEndSalaryFocus}
                    onBlur={onEndSalaryBlur}
                    autoComplete="off"
                    required
                  />
                  <label>Ending Salary($) or (Negotiable)</label>
                </div>
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) => setNewTag(event.target.value)}
                    minLength={4}
                    
                    className={createTagFocus}
                    onFocus={onCreateTagFocus}
                    onBlur={onCreateTagBlur}
                    autoComplete="off"
                    
                  />
                  <label>New Tag</label>
                </div>
                <div className="input-wrap">
                <button className="btn" onClick={handleCreateTag}>Create Tag</button>
                </div>
                <div className="input-wrap">
                  <Select
                    options={tagsList}
                    placeholder="Industry Tags"
                    value={selectedTags}
                    onChange={handleSelectTags}
                    isSearchable={true}
                    isMulti
                  />
                </div>
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) => setNewOfferType(event.target.value)}
                    minLength={4}
                    className={createNewOfferTypeFocus}
                    onFocus={onCreateOfferTypeFocus}
                    onBlur={onCreateOfferTypeBlur}
                    autoComplete="off"
                    
                  />
                  <label>Create a New Offer Type</label>
                </div>
                <div className="input-wrap">
                <button className="btn" onClick={handleCreateOfferType}>Create Offer Type</button>
                </div>
                <div className="input-wrap">
                  <Select
                    options={offerTypes}
                    placeholder="Choose Offer Type"
                    value={selectedOfferType}
                    onChange={handleSelectOfferType}
                    isSearchable={true}
                  />
                </div>
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) => setContactName(event.target.value)}
                    minLength={4}
                    value={contactName}
                    className={contactNameFocus}
                    onFocus={onContactNameFocus}
                    onBlur={onContactNameBlur}
                    autoComplete="off"
                    required
                  />
                  <label>Contact Person Name</label>
                </div>
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) => setContactEmail(event.target.value)}
                    minLength={4}
                    value={contactEmail}
                    className={contactEmailFocus}
                    onFocus={onContactEmailFocus}
                    onBlur={onContactEmailBlur}
                    autoComplete="off"
                    required
                  />
                  <label>Contact Person Email</label>
                </div>
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) => setContactPhone(event.target.value)}
                    minLength={4}
                    value={contactPhone}
                    className={contactPhoneFocus}
                    onFocus={onContactPhoneFocus}
                    onBlur={onContactPhoneBlur}
                    autoComplete="off"
                    required
                  />
                  <label>Contact Person Phone</label>
                </div>
                <div className="input-wrap">
                  <input
                    type="text"
                    onChange={(event) => setDetailInfo(event.target.value)}
                    minLength={4}
                    value={detailInfo}
                    className={detailInfoFocus}
                    onFocus={onDetailInfoFocus}
                    onBlur={onDetailInfoBlur}
                    autoComplete="off"
                    required
                  />
                  <label>Enter Detail Information</label>
                  
                </div>
                
                <div >
                <h3>Upload File</h3>
            <div>
            <input type="file" className="btn" accept=".docx,.doc,.pdf" id="file-input" name="file-input" onChange={handleFileChange}   />
              
              {selectedFile && (
                <div className="flex row">
                  <p>Selected File: {selectedFile.name}</p>
                   
                  <button className="btn" onClick={viewFile}> <span>
                    {selectedFile.type === 'application/pdf' && (
                      <AiFillFilePdf className="icon"/> 
                    )}
                    {selectedFile.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && (
                      <AiFillFileWord/>
                    )}
                  </span>   View File</button>
                  <button className="btn" onClick={removeFile}><span className="material-icons"/>Remove File</button>
                </div>
              )}
            </div>

            </div>
                <PaddedDiv/>
                <div className="sign-btn">
                  <input type="submit" onClick={handleAddOffer} value="Edit Offer" className="sign-btn" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
            >
        <CircularProgress color="inherit" />
        
      </Backdrop>
      {error && (
         
         <div className="overlay">
         <div className="error-popup">
           <p>Can not update opportunities check inputs</p>
           <button onClick={() => setError(false)}>Close</button>
         </div>
       </div>
     )}
   </div>
  );
  
};

export default EditOpportunity;
