import React, { useState, useEffect } from "react";
import { DateField } from "@mui/x-date-pickers";
import axios from "axios";
import Top from "../top section/Top";
import "./add_company.css";
import Select from "react-select";
import Cookies from "universal-cookie";
import { BASE_URL } from "../../../context/config";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
const AddCompany = (props) => {
  const cookies = new Cookies();
  const session = useState(cookies.get("sessionid"));
  const [companyFocus, setCompanyFocus] = useState("input-field");
  const [companyName, setCompanyName] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [emailFocus, setEmailFocus] = useState("input-field");
  const [companyEmail, setCompanyEmail] = useState("");
  const [phoneFocus, setPhoneFocus] = useState("input-field");
  const [companyPhone, setCompanyPhone] = useState("");
  const [industryFocus, setIndustryFocus] = useState("input-field");
  const [companyIndustry, setCompanyIndustry] = useState("");
  const [cityFocus, setCityFocus] = useState("input-field");
  const [companyCity, setCompanyCity] = useState("");
  const [streetFocus, setStreetFocus] = useState("input-field");
  const [companyStreet, setCompanyStreet] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  function handleSelectCountry(country) {
    setSelectedCountry(country);
  }
  function onCompanyFocus() {
    setCompanyFocus("input-field active");
  }

  function onCompanyBlur() {
    if (companyName == "") {
      setCompanyFocus("input-field");
    }
  }
  function onCompanyEmailFocus() {
    setEmailFocus("input-field active");
  }

  function onCompanyEmailBlur() {
    if (companyEmail == "") {
      setEmailFocus("input-field");
    }
  }
  function onCompanyPhoneFocus() {
    setPhoneFocus("input-field active");
  }

  function onCompanyPhoneBlur() {
    if (companyPhone == "") {
      setPhoneFocus("input-field");
    }
  }
  function onCompanyIndustryFocus() {
    setIndustryFocus("input-field active");
  }

  function onCompanyIndustryBlur() {
    if (companyIndustry == "") {
      setIndustryFocus("input-field");
    }
  }

  function onCompanyCityFocus() {
    setCityFocus("input-field active");
  }

  function onCompanyCityBlur() {
    if (companyCity == "") {
      setCityFocus("input-field");
    }
  }
  function onCompanyStreetFocus() {
    setStreetFocus("input-field active");
  }

  function onCompanyStreetBlur() {
    if (companyStreet == "") {
      setStreetFocus("input-field");
    }
  }
  const handlePopupClose = () => {
    setError(null);
  };

  useEffect(() => {
    // Define the API endpoint to get the list of countries
    const apiEndpoint = "https://restcountries.com/v3.1/all";

    // Fetch the data from the API using Axios
    axios
      .get(apiEndpoint)
      .then((response) => {
        // Handle successful response and update the 'countries' state
        setCountries(response.data);
        const countriesData = response.data.map((country) => ({
          value: country.cca2,
          label: country.name.common,
        }));
        setCountries(countriesData);
      })
      .catch((error) => {
        // Handle error if the request fails
        console.error("Error fetching countries:", error);
      });
  }, []);
  const add_company = (e) => {
    setIsLoading(true);
    const companyData = {
      primaryemail: session[0].primaryemail,
      sessionid: session[0].sessionid,
      city: companyCity,
      country: selectedCountry.label,
      industry: companyIndustry,
      street: companyStreet,
      companyemail: companyEmail,
      companyname: companyName,
      companyphone: companyPhone,
    };
    console.log(companyData);
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(`${BASE_URL}/entities/company/createcompany`, companyData, {
        headers,
      })
      .then((response) => {
        navigate("/companies");
      })
      .catch((error) => {
        setError("Could not add company");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div className="mainContent2">
      <Top />
      <div className="mainContent2">
        <div className="box">
          <div className="inner-box">
            <div className="forms-wrap-add_company">
              <div className="form sign-in-form">
                <div className="heading">
                  <h2>Add New Company</h2>
                </div>

                <div className="actual-form">
                  <div className="input-wrap">
                    <input
                      type="text"
                      onChange={(event) => setCompanyName(event.target.value)}
                      minLength={4}
                      className={companyFocus}
                      onFocus={onCompanyFocus}
                      onBlur={onCompanyBlur}
                      autoComplete="off"
                      required
                    />
                    <label>Company Name</label>
                  </div>
                  <div className="input-wrap">
                    <input
                      type="text"
                      onChange={(event) => setCompanyEmail(event.target.value)}
                      minLength={4}
                      className={emailFocus}
                      onFocus={onCompanyEmailFocus}
                      onBlur={onCompanyEmailBlur}
                      autoComplete="off"
                      required
                    />
                    <label>Company Email</label>
                  </div>
                  <div className="input-wrap">
                    <input
                      type="text"
                      onChange={(event) => setCompanyPhone(event.target.value)}
                      minLength={4}
                      className={phoneFocus}
                      onFocus={onCompanyPhoneFocus}
                      onBlur={onCompanyPhoneBlur}
                      autoComplete="off"
                      required
                    />
                    <label>Company Phone</label>
                  </div>
                  <div className="input-wrap">
                    <input
                      type="text"
                      onChange={(event) =>
                        setCompanyIndustry(event.target.value)
                      }
                      minLength={4}
                      className={industryFocus}
                      onFocus={onCompanyIndustryFocus}
                      onBlur={onCompanyIndustryBlur}
                      autoComplete="off"
                      required
                    />
                    <label>Company Industry</label>
                  </div>
                  <div className="input-wrap">
                    <Select
                      options={countries}
                      placeholder="Country"
                      value={selectedCountry}
                      onChange={handleSelectCountry}
                      isSearchable={true}
                    />
                  </div>
                  <div className="input-wrap">
                    <input
                      type="text"
                      onChange={(event) => setCompanyCity(event.target.value)}
                      minLength={4}
                      className={cityFocus}
                      onFocus={onCompanyCityFocus}
                      onBlur={onCompanyCityBlur}
                      autoComplete="off"
                      required
                    />
                    <label>Company City</label>
                  </div>

                  <div className="sign-btn">
                    <input
                      type="submit"
                      onClick={add_company}
                      value="Register Company"
                      className="sign-btn"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {error && (
        <div className="error-popup">
          <div className="error-content">
            <span>{error}</span>
            <button onClick={handlePopupClose}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddCompany;
