import React, {useEffect} from 'react'
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { mockDataCompanies } from "../mockData";
import { Link } from 'react-router-dom';
import './companies.css'
import { BASE_URL } from '../../../context/config';
import axios from "axios";
import Top from "../top section/Top";
import { BiSearchAlt } from "react-icons/bi";
import { useState } from "react";
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';

const Companies = () => {
  const cookies = new Cookies()
  const session = useState(cookies.get('sessionid'));
  const navigate = useNavigate();  
  const theme = useTheme();
  const [companies, setCompanies] = useState([])
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLoading] = useState(false)
  const [selectedRows, setSelectedRows] = useState([]);
  const [error, setError] = useState(null);
  const [allCompanies, setAllCompanies] = useState(null);
  const handleSelectionChange = (selection) => {
   
    setSelectedRows(selection);
    console.log(selection)
  };
  const handleSearch = (value) => {
    console.log(value)
    // If the search input is empty, set the companies state to allCompanies
    if (value.trim() === "") {
      setCompanies(allCompanies);
    } else {
      // Filter the companies based on the search input
      const filteredCompanies = allCompanies.filter(
        (company) =>
          company.name.toLowerCase().includes(value.toLowerCase()) ||
          company.country.toLowerCase().includes(value.toLowerCase())
        // You can add more properties to search in if needed
      );

      setCompanies(filteredCompanies);
    }
  };
  const handleDeleteCompany = (e) => {
    if (selectedRows.length ==0) {
      setError("You must select at least one element")
    } else {
      setIsLoading(true)
      const toDelete = selectedRows;
      for (let i =0; i<toDelete.length; i++){
        const filteredData = companies.filter((item) => item.id == toDelete[i])[0];
        console.log(filteredData);
        const deleteData = {
          primaryemail: session[0].primaryemail,
          sessionid: session[0].sessionid,
          companyname: filteredData.name,
          country: filteredData.country
        };
        console.log(deleteData)
        const headers = {
          "Content-Type": "application/json",
        };
        // Fetch the data from the API using Axios
    axios.post(`${BASE_URL}/entities/company/deletecompany`, deleteData, {headers})
    .then(response => {
        
      })
      .catch(error => {
        // Handle error if the request fails
        setError("Error deleting company")
      }).finally(()=>{
        setIsLoading(false);
      });
      }
      setCompanies(companies.filter(item => !selectedRows.includes(item.id)));
    }
  }
  const handleEditCompany = (e) => {
    if (selectedRows.length !=1) {
      setError("Editing is allowed only for a single element")
    }
    else {
      
      navigate(
        '../edit_company',
       { state: { data: companies.filter(item => selectedRows.includes(item.id))[0] }}
      );    }
  }
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "industry",
      headerName: "Industry",
      flex: 1,
    },
    {
      field: "dateadded",
      headerName: "Registration Date",
      flex: 1,
    },
  ];
  useEffect(() => {
    // Define the API endpoint to get the list of countries
    setIsLoading(true)
    const companyData = {
        primaryemail: session[0].primaryemail,
        sessionid: session[0].sessionid,
        

      };
    const headers = {
      "Content-Type": "application/json",
    };

    
    axios.post(`${BASE_URL}/entities/company/getallcompanyies`, companyData, {headers})
    .then(response => {
        // Handle successful response and update the 'countries' state
        var tempData = response.data.body 
        tempData.forEach(obj => {
          obj.id = obj.companyid;
          const dateString = obj.dateadded;
          const date = new Date(dateString);

          const day = date.getDate().toString().padStart(2, '0');
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const year = date.getFullYear();

          const formattedDate = `${day}-${month}-${year}`;
          obj.dateadded = formattedDate
          
          delete obj.companyid;
      });
        setCompanies(tempData);
        setAllCompanies(tempData)
       
      })
      .catch(error => {
        // Handle error if the request fails
        console.error('Error fetching countries:', error);
        setError("Error fetching companies")
      }).finally(()=>{
     setIsLoading(false)
      });
  }, []);
  const handlePopupClose = () => {
    setError(null);
  };
  return (
    <div className="mainContent">
        <Top/>
        <div className="heading flex">
        <h1>Companies</h1>
        <div className="tools flex">
        <Link to="../add_company">
                <button className="btn">Add Company</button>
        </Link>
        
        <button className="btn" onClick={handleEditCompany}>Edit Company</button>
       
        <button className="btn" onClick={handleDeleteCompany}>Delete Company</button>
          <div className="searchBar flex">
            <input type="text" placeholder="Search Company" onChange={(event) =>handleSearch(event.target.value) } />
            <BiSearchAlt className="icon" />

            <div className="searchHints">
        <span className="hint">Search by name <br/></span>
        
        <span className="hint">Search by country</span>
      </div>
          </div>
         
        </div>
      </div>
    <Box m="20px">

      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid checkboxSelection 
        rows={companies} 
        columns={columns}
        onSelectionModelChange={handleSelectionChange} />
      </Box>
    </Box>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
            >
        <CircularProgress color="inherit" />
        
      </Backdrop>
      {error && (
         
         <div className="overlay">
         <div className="error-popup">
           <p>{error}</p>
           <button onClick={() => setError(false)}>Close</button>
         </div>
       </div>
     )}
   </div>
  );
};

export default Companies;
