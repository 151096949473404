import React, { useEffect, useState } from "react";
import "./top.css";

import InitialsAvatar from "react-initials-avatar";
import "react-initials-avatar/lib/ReactInitialsAvatar.css";

import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";

import { HiOutlineMenuAlt1 } from "react-icons/hi";
import MobileNav from "../../MobileNav";
const Top = () => {
  const [showNav, setshowNav] = useState(false);
  const [open, setOpen] = useState(false);
  const cookies = new Cookies();
  const navigate = useNavigate();
  const session = useState(cookies.get("sessionid"));

  const handleMouseEnter = () => {
    setOpen(true);
  };
  const handleMouseLeave = () => {
    setOpen(false);
  };

  const handleResetPassword = () => {
    navigate("../reset_password");
    window.location.reload();
  };
  const handleClick = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    // Delete the sessionid cookie (replace 'sessionid' with the actual cookie key used for the session)
    document.cookie =
      "sessionid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    // Navigate the user to the login page (you can use the appropriate URL for your login page)
    window.location.href = "/login"; // Replace '/login' with the correct login page URL
  };

  return (
    <div
      className="topSection"
      style={{
        marginBottom: 20,
      }}
    >
      {showNav && (
        <div className="mobile-backdrop">
          <MobileNav showNav={showNav} setshowNav={setshowNav} />{" "}
        </div>
      )}
      <div className="headerSection flex">
        <div className="header-left_cover">
          <div className="header-left_icon" onClick={() => setshowNav(true)}>
            <HiOutlineMenuAlt1 size={25} />
          </div>
          <div className="title">
            <h1 className="header-title">
              Welcome to Career Service Opportunities Board.
            </h1>
            <p className="header-caption">
              Hello {session[0].firstname}, Welcome back!
            </p>
          </div>
        </div>

        <div className="menu-container">
          <div
            className="menu-trigger"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
          >
            {/* Use InitialsAvatar instead of <img> */}
            <InitialsAvatar
              name={session[0].firstname + " " + session[0].lastname} // Replace with the user's name
              color="#3498db" // Replace with the desired color for the avatar background
              textColor="#ffffff" // Replace with the desired color for the initials text
              size={70} // Replace with the desired size of the avatar
            />
          </div>
          {open && (
            <div
              className="dropdown-menu"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <h3>{session[0].firstname + " " + session[0].lastname}</h3>
              <ul>
                {/* <DropDownItem text={"My Profile"} /> */}
                <DropDownItem
                  text={"Reset Password"}
                  handleClick={handleResetPassword}
                />
                <DropDownItem text={"Logout"} handleClick={handleLogout} />
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
function DropDownItem(props) {
  const { text, handleClick } = props;

  const handleItemClick = () => {
    if (handleClick) {
      handleClick();
    }
  };

  return (
    <li className="dropDownItem">
      <a href="#" onClick={handleItemClick}>
        {text}
      </a>{" "}
      {/* href="#" allows the items to be clickable, but you should replace "#" with the actual link */}
    </li>
  );
}

export default Top;
// The rest of your code (DropDownItem component) remains the same
