import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import '../auth.css'
import logo_map from '../../../assets/log_map.png'
import axios from 'axios';
import Cookies from 'universal-cookie';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {BASE_URL} from '../../../context/config.js'
import {useAuth} from '../../../context/auth_context.js'
import { useNavigate } from 'react-router-dom';
export default function SetPassword() {

    const [useFocus, setUseFocus] = useState('input-field');
    const [passFocus, setPassFocus] = useState('input-field');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassFocus, setConfirmPassFocus] = useState('input-field');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null);
    const navigate = useNavigate();  
    const href = window.location.href.toString();

    // Split the hash string by '/' to get an array of parts
    const parts = href.split('/');

    // Get the last part of the route (which is "register" in this case)
    const lastPart = parts[parts.length-1];

    const resetKeyID = lastPart
    

    function onUserFocus(){
        setUseFocus('input-field active');
    }
    function onPassFocus(){
        setPassFocus('input-field active');
    }
    function onUserBlur(){
       if (userName==""){
        setUseFocus("input-field")
       }
    }
    function onPassBlur(){
        if (password==""){
            setPassFocus("input-field")
           }
    }
    function onConfirmPassFocus(){
      setConfirmPassFocus('input-field active');
  }
  function onConfirmPassBlur(){
     if (confirmPassword==""){
      setConfirmPassFocus("input-field")
     }
  }
  const handleReset = () => {

    const resetPayload = {
        primaryemail:userName,
        newpassword:password,
        resetkey:resetKeyID,
        password:password,
        
    }
    console.log(resetPayload)
    const headers = {
        "Content-Type": "application/json",
      };
      axios
        .post(`${BASE_URL}/auth/resetpasswordforforgotpassword`, resetPayload, {
          headers,
        })
        .then((response) => {
          console.log(response)
          navigate('/login')
        })
        .catch((error) => {
          console.log(error)
          navigate('/login')
          setError("Could not reset password");
        })
        .finally(() => {
  
          setIsLoading(false);
        });   
    
  }
  return (
    <div className='main'>
        <div className="box">
        <div className="inner-box">
            <div className="forms-wrap"> 
                <div className="form sign-in-form">
                    <div className="logo">
                        <img src={logo_map} alt="CMU-Africa"/>
                        
                    </div>
                    <div className="heading">
                        <h2>Opportunities Board</h2>
                    </div>
                    <div className="heading">
                        <h2>Reset password</h2>
                    </div>
                    <div className="actual-form">
                        <div className="input-wrap">
                            <input type='text'  onChange={(event) =>setUserName(event.target.value) }  minLength={4} className={useFocus} onFocus={onUserFocus} onBlur={onUserBlur} autoComplete='off' required/>
                            <label >
                                Enter primary email
                            </label>
                        </div>
                        <div className="input-wrap">
                            <input type='password' onChange={(event) =>setPassword(event.target.value) } minLength={4} className={passFocus} onFocus={onPassFocus}  onBlur={onPassBlur} autoComplete='off' required/>
                            <label >
                            Password
                            </label>
                        </div>
                        <div className="input-wrap">
                            <input type='password' onChange={(event) =>setConfirmPassword(event.target.value) } minLength={4} className={confirmPassFocus} onFocus={onConfirmPassFocus}  onBlur={onConfirmPassBlur} autoComplete='off' required/>
                            <label >
                            Confirm Password
                            </label>
                        </div>
                        <div className="sign-btn">
                            <input type='submit' value="Reset Password" onClick={handleReset} className='sign-btn'/>
                        </div>
                       
                    </div>
                </div>
            </div>
            <div className="carousel"></div>
        </div>
        </div>
        <Helmet>
              <script src="./login.js" type="text/jsx" />
        </Helmet>
    </div>
  );
  
}
