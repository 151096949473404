import React, { useState, useMemo, useEffect } from "react";
import { sideBarAdmin, sideBarDataUser, sideBarManager } from "./sidebardata";
import { useNavigate, Outlet } from "react-router-dom";
import SideBar from "./components/sidebar/SideBar";

import Cookies from "universal-cookie";

function PrivateRoutes() {
  const navigate = useNavigate();
  const cookies = new Cookies();

  const [loading, setloading] = useState(true);
  const session = useState(cookies.get("sessionid"));

  useEffect(() => {
    if (!session[0]?.sessionid) {
      // navigate("/login");
      cookies.remove("sessionid");
      window.location.href = "/login";
      return;
    }
    setloading(false);

    try {
      if (session[0].role === "systemadmin") {
        return <SideBar sideData={sideBarAdmin} />;
      } else if (session[0].role == "admin") {
        return <SideBar sideData={sideBarManager} />;
      } else if (session[0].role === "user") {
        return <SideBar sideData={sideBarDataUser} />;
      } else {
        return null; // Return default or empty content if none of the conditions match
      }
    } catch (error) {
      navigate("/login");
    }
  }, []);

  const sidebarContent = useMemo(() => {
    try {
      if (session[0].role === "systemadmin") {
        return <SideBar sideData={sideBarAdmin} />;
      } else if (session[0].role == "admin") {
        return <SideBar sideData={sideBarManager} />;
      } else if (session[0].role === "user") {
        return <SideBar sideData={sideBarDataUser} />;
      } else {
        return null; // Return default or empty content if none of the conditions match
      }
    } catch (error) {
      navigate("/login");
    }
  }, [session[0]]);
  return (
    <div className="container">
      {loading ? (
        <div
          className=""
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="">fetching resource...</div>
        </div>
      ) : (
        <>
          {session[0] !== null && sidebarContent}
          <Outlet />
        </>
      )}
    </div>
  );
}

export default PrivateRoutes;
