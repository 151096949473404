import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import '../auth.css'
import logo_map from '../../../assets/log_map.png'
import axios from 'axios';
import Cookies from 'universal-cookie';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {BASE_URL} from '../../../context/config.js'
import {useAuth} from '../../../context/auth_context.js'
import { useNavigate } from 'react-router-dom';
export default function ForgetPassword() {
    const navigate = useNavigate()
    const [useFocus, setUseFocus] = useState('input-field');
    const [passFocus, setPassFocus] = useState('input-field');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null);
    function onUserFocus(){
        setUseFocus('input-field active');
    }
    function onPassFocus(){
        setPassFocus('input-field active');
    }
    function onUserBlur(){
       if (userName==""){
        setUseFocus("input-field")
       }
    }
    function onPassBlur(){
        if (password==""){
            setPassFocus("input-field")
           }
    }
    const handleForgetPassword = () => {
        setIsLoading(true)
        const resetPayload = {
            primaryemail:userName,
           
        }
       
        const headers = {
            "Content-Type": "application/json",
          };
          axios
            .post(`${BASE_URL}/auth/sendforgotpasswordemail`, resetPayload, {
              headers,
            })
            .then((response) => {
              console.log(response)
              navigate('/login')
            })
            .catch((error) => {
              console.log(error)
             setError(true)
            })
            .finally(() => {
                setIsLoading(false)
            });   
        
      }
      const handlePopupClose = () => {
        setError(null);
      };
  return (
    <div className='main'>
        <div className="box">
        <div className="inner-box">
            <div className="forms-wrap"> 
                <div className="form sign-in-form">
                    <div className="logo">
                        <img src={logo_map} alt="CMU-Africa"/>
                        
                    </div>
                    <div className="heading">
                        <h2>Opportunities Board</h2>
                    </div>
                    <div className="heading">
                        <h2>Reset Password</h2>
                    </div>
                    <div className="actual-form">
                        <div className="input-wrap">
                            <input type='text'  onChange={(event) =>setUserName(event.target.value) }  minLength={4} className={useFocus} onFocus={onUserFocus} onBlur={onUserBlur} autoComplete='off' required/>
                            <label >
                                Email to Send Link
                            </label>
                        </div>
                       
                        <div className="sign-btn">
                            <input type='submit' onClick={handleForgetPassword} value="Send Forget Password Link" className='sign-btn'/>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="carousel"></div>
        </div>
        </div>
        <Helmet>
              <script src="./login.js" type="text/jsx" />
        </Helmet>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
            >
        <CircularProgress color="inherit" />
        
      </Backdrop>
      {error && (
        <div className="error-popup">
          <div className="error-content">
            <span>{error}</span>
            <button onClick={handlePopupClose}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
  
}
