import Chart from "chart.js/auto";
import React from "react";
import { Line, Bar, Pie } from "react-chartjs-2";

const TagPieChart = ({ data }) => {
  function generateUniqueHexColors(n) {
    const uniqueColors = new Set();

    while (uniqueColors.size < n) {
      // Generate a random hex color
      const randomColor =
        "#" + Math.floor(Math.random() * 16777215).toString(16);

      // Add it to the set to ensure uniqueness
      uniqueColors.add(randomColor);
    }

    // Convert the set to an array
    const result = Array.from(uniqueColors);
    return result;
  }

  return (
    <Pie
      data={{
        labels: data.map((word) => word.value),
        datasets: [
          {
            data: data.map((word) => word.count),
            backgroundColor: generateUniqueHexColors(data.length),
            hoverOffset: 4,
          },
        ],
      }}
      //   data={{
      //     labels: ["Male", "Female"],
      //     datasets: [
      //       {
      //         label: "Gender",
      //         data: !Object.entries(data).length
      //           ? []
      //           : [
      //               (+data.Male / +data.Male + +data.Female).toFixed(2) * 100,
      //               (+data.Female / +data.Male + +data.Female).toFixed(2) * 100,
      //             ],
      //         backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)"],
      //         hoverOffset: 4,
      //       },
      //     ],
      //   }}
      options={{
        // datalabels: {
        //   formatter: (value, ctx) => {
        //     let sum = 0;
        //     let dataArr = ctx.chart.data.datasets[0];
        //     dataArr.map((data) => {
        //       sum += data;
        //     });
        //     let percentage = ((value * 100) / sum).toFixed(2) + "%";
        //     return percentage;
        //   },
        //   color: "#fff",
        // },
        responsive: true,
        aspectRatio: 2.5,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
          },
          title: {
            display: true,
          },
        },
      }}
    />
  );
};

export default TagPieChart;
